// @flow

import { useDispatch } from "react-redux";
import React, { useCallback } from "react";

import { downloadFile } from "src/actions/file";
import Icon from "src/icons";
import { getFileType } from "src/utils/file";
import { FileIcon } from "src/components/Dock/styles.js";
import { StyledThumbnail } from "./styles";
import FileHoverPreview from "src/components/file/HoverPreview";
import useFileViewer from "src/hooks/useFileViewer";

import type { UnifizeFile, RoomId } from "src/types";

type Props = {
  file: UnifizeFile,
  roomId: RoomId,
  settings: string
};

const File = ({ file, roomId, settings }: Props) => {
  const dispatch = useDispatch();

  const { openFile } = useFileViewer({
    file,
    modal: false,
    roomId
  });

  const handleFileOpen = useCallback(() => {
    const fileType = getFileType(file.originalName);
    if (fileType === "image" || fileType === "pdf") {
      openFile();
    } else {
      dispatch(
        downloadFile({
          roomId,
          location: "manage-view",
          name: file.name,
          originalName: file.originalName,
          generation: file.generation,
          versionId: file.versionId
        })
      );
    }
  }, [file, getFileType]);

  if (!file) {
    return null;
  }

  return (
    <FileHoverPreview file={file} roomId={roomId} settings={settings}>
      <StyledThumbnail onClick={handleFileOpen}>
        <FileIcon>
          <Icon
            type="fileIcons"
            icon={getFileType(file.originalName)}
            className="icon"
            cursor="pointer"
          />
        </FileIcon>
      </StyledThumbnail>
    </FileHoverPreview>
  );
};

export default File;

// @flow

import * as R from "ramda";
import {
  importProcessesFromJSON,
  importJSONCollisions
} from "src/api/workflow";
import type {
  ImportedProcess,
  ImportedProcessStatus,
  Workflow
} from "src/types";

/**
 * Imports the given list of JSON imported processes using the import
 * API
 * @param {string} filename - The UUID of the uploaded JSON returned by
 * the storage
 * @param {Array<ImportedProcess>} importProcesses - A list of processes
 * parsed out from the JSON file
 */
const handleJSONImport = async (
  filename: string,
  importProcesses: Array<ImportedProcess>
) => {
  let copies = [];
  let excludes = [];
  importProcesses.forEach(p => {
    if (p.copy) {
      copies.push(p.id);
    } else if (p.exclude) {
      excludes.push(p.id);
    }
  });
  const res = await importProcessesFromJSON(
    filename,
    excludes || null,
    copies || null
  );
  const result: Array<
    ImportedProcess & {
      status: ImportedProcessStatus
    }
  > = await res.json();
  return result;
};

/**
 * Fetches the list of processes colliding for the given JSON-import
 * file and returns it
 * @param {string} filename - The UUID of the uploaded JSON returned by
 * the storage
 * @return A list of the collision processes or null
 */
const fetchJSONImportCollisions = async (
  fileName: string
): Promise<?{ [string]: Array<ImportedProcess> }> => {
  try {
    const res = await importJSONCollisions(fileName);
    const data: { collisions: Array<ImportedProcess> } = await res.json();
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

/**
 * Appends a '(1)' and a zero-width space Unicode character to the given
 * JSON import process's unmodified title as a modification flag.
 * Removes the character if it's already present.
 * @param {ImportedProcess} process - The imported process to be used
 * for the title
 * @return {string} The modified title
 */
const appendImportTitleString = (process: ImportedProcess) => {
  if (process.title.endsWith("\u200B")) {
    return process.title.slice(0, -5);
  } else {
    return process.title.concat(" (1)\u200B");
  }
};

/**
 * Formats the auto number correctly for chatRoom state by appending the
 * current version marker ('C') if necessary
 * @param {string} autoNo - The auto number of a UnifizeChatroom
 * @return {string} The formatted auto number
 */
const formatAutoNoForVersion = (
  autoNo: ?string,
  versionCount: number,
  currentVersion?: boolean
) => {
  if (autoNo && versionCount > 1 && currentVersion) {
    return `${autoNo}C`;
  }
  return autoNo;
};

/**
 * Checks if the process attached to a linked field has been deleted
 * @param {Object} process details
 * @param {number | null} processId
 * @return {boolean}
 */
const checkIfProcessDeleted = (process: Workflow, processId: number | null) => {
  // If there's a valid process ID attached it means there was a process
  // attached to this field previously
  if (processId !== null) return R.isEmpty(process) || process?.deleted;
  return false;
};

export {
  handleJSONImport,
  fetchJSONImportCollisions,
  appendImportTitleString,
  formatAutoNoForVersion,
  checkIfProcessDeleted
};

import React from "react";
import { SelectContainer, Heading } from "./styles";

const SelectChatComponent = () => {
  return (
    <SelectContainer>
      <Heading>Select a record</Heading>
    </SelectContainer>
  );
};

export default SelectChatComponent;

// @flow

import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { Form } from "src/styles/form";
import { ListItemMedium, InlineBox, Box } from "src/styles/box";
import * as spacing from "src/styles/constants/spacing";
import * as colors from "src/styles/constants/colors";
import * as radius from "src/styles/constants/radius";
import * as typography from "src/styles/constants/typography";
import Dropdown from "src/styles/dropdown";
import { colors as oldColors } from "src/styles/constants";

export const Invite = styled(Form)`
  position: absolute;
  margin: auto;
  margin-bottom: ${spacing.space_m};
  top: 4px;
  width: 100%;
  z-index: 1;
`;

export const SearchResult = styled(ListItemMedium)`
  > span > span {
    max-width: 14em !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const FileLabel = styled.div`
  font-size: 10px;
  font-family: Lato;
  padding-left: 4px;
  flex: 0 0 auto;
`;

export const Favourite = styled(InlineBox)`
  margin-right: ${spacing.space_s};
`;

export const ProgressBar = styled(Box)`
  position: relative;
  height: auto;
  border-radius: ${radius.medium};
  box-shadow: 0 0 6px 0 rgba(42, 42, 42, 0.08);
  min-width: 20.7em;
  padding: ${spacing.space_inset_l};
  margin-bottom: 0;
  margin-left: 1.4em;
  margin-right: 5.5em;
  top: -0.2em;
  background: ${colors.brightLight};

  ${props =>
    props.isSrwMobile
      ? css`
          margin: 0 16px !important;
          width: calc(100% - 32px);
          min-width: initial;
          left: 0;
        `
      : css``}

  > div > b {
    font-weight: bold;
  }

  > div:last-child {
    display: flex;
    flex-flow: row;

    > div {
      margin: ${spacing.space_inline_default};
    }

    > div:first-of-type {
      flex-grow: 1;
    }

    > div:last-child {
      cursor: pointer;
      display: inline-flex;
    }
  }
`;

export const FileReply = styled.div`
  display: flex;
  align-items: flex-end;
  padding: initial !important;
  > div > div {
    font-size: 0.75rem !important;
    margin-left: 0px !important;
    font-weight: ${typography.light} !important;
    line-height: 1.125rem;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

export const UploadButton = styled.div`
  width: 100%;
  display: initial;
  justify-content: center;
  align-items: center;

  > button {
    padding: 7px 50px 0 0;
    width: 100%;
    height: 80%;
    right: 0px;
    top: 0px;
  }

  > svg {
    float: right;
    position: relative;
    top: 0.5em;
    right: 1em;
    cursor: pointer;
  }

  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

export const AttachButton = styled.button`
  cursor: pointer;
  outline: none;
  background: inherit;
  border: none;
  width: 118px;
  padding-left: 21px;
  position: absolute;
  right: 72px;
  top: 8px;
`;

export const Selection = styled.div`
  padding: 1.2em;
  background-color: ${colors.grey16};
  margin: 0 !important;
  color: ${colors.active};
  font-size: 0.88rem;
  width: calc(100% - 3em);
  position: relative;
  display: flex;
  > div:first-of-type {
    max-width: 210px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Checklist = styled.strong`
  margin-left: 10em;
  > span {
    white-space: nowrap;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RemoveIcon = styled.div`
  position: absolute;
  right: 0.3em;
  background-color: ${oldColors.disabledBorderGrey};
  width: 1.2em;
  height: 1.2em;
  border-radius: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg {
    cursor: pointer !important;
    width: 0.6em;
    height: 0.6em;
  }
`;

export const DropdownModal = styled(Dropdown)`
  bottom: 15%;
`;

export const styles = {
  roleSelect: {
    width: "50%"
  }
};

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9fafa;
  height: 100%;
`;

export const Heading = styled.h2`
  color: #d9d9d9;
  font-size: 1.5rem;
  font-weight: 600;
`;

// @flow

import React, { useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { showFileUploadModal } from "src/actions/file";
import {
  getFileUploadModalStatus,
  getCurrentRoomId,
  getIsSrwMobile,
  getCurrentRoomAccess
} from "src/reducers";
import { currentRoomAccessStatuses } from "src/reducers/chatRooms";

import Overlay from "./Overlay";
import ChatHistory from "src/components/messages/History";
import ChatInput from "src/components/chatroom/Input";
import ChatroomHeader from "src/components/chatroom/Header";
import PreviewModal from "src/components/file/PreviewModal";
import RestrictedModal from "./RestrictedModal";
import { Current as StyledCurrent, Main, ChatArea } from "src/styles";
import Dock from "src/containers/CurrentRoomDock";

import { getDefaultChatroom } from "src/reducers";
import SelectChatComponent from "./SelectChatComponent";

const Current = () => {
  const dispatch = useDispatch();
  const currentRef = useRef(null);
  const mainContainerRef = useRef(null);

  const roomId = useSelector(({ app }) => getCurrentRoomId(app));
  const isSrwMobile = useSelector(({ app }) => getIsSrwMobile(app));

  const defaultChatroom = useSelector(({ app }) => getDefaultChatroom(app));

  const onDrop = useCallback(
    acceptedFiles => {
      dispatch(showFileUploadModal({ viewId: roomId, files: acceptedFiles }));
    },
    [dispatch, roomId]
  );

  const { getRootProps, isDragActive } = useDropzone({ onDrop });
  const dragAndDropProps = getRootProps();

  const showFilesPreviewModal = useSelector(({ app }) =>
    getFileUploadModalStatus(app, roomId)
  );

  const currentRoomAccessStatus = useSelector(({ app }) =>
    getCurrentRoomAccess(app)
  );

  const currentRoomRestricted =
    currentRoomAccessStatus === currentRoomAccessStatuses.restricted;

  return defaultChatroom ? (
    <SelectChatComponent />
  ) : (
    <>
      <RestrictedModal />
      <StyledCurrent ref={currentRef}>
        {showFilesPreviewModal && <PreviewModal roomId={roomId} />}
        {!currentRoomRestricted && (
          <>
            <ChatroomHeader currentRef={currentRef} />

            <Main ref={mainContainerRef}>
              <ChatArea
                {...dragAndDropProps}
                isDragActive={isDragActive}
                onFocus={null}
                onBlur={null}
              >
                <ChatHistory />
                <ChatInput />
              </ChatArea>
              {!isSrwMobile && <Dock parentRef={mainContainerRef} />}
              <Overlay />
            </Main>
          </>
        )}
      </StyledCurrent>
    </>
  );
};

export default Current;

import k from "./keys";

export default {
  [k._]: `:`,
  [k._1]: `(`,
  [k._2]: `)`,
  [k.LIGHT]: `Light`,
  [k.ANGEL]: `Angel`,
  [k.DARK]: `Dark`,
  [k.DEMON]: `Demon`,
  [k.SELECT_ALL]: `Select all`,
  [k.DESELECT_ALL]: `Deselect all`,
  [k.ALSBVYETHVN]: `alsbvyethvn`,
  [k.ALASKA]: `alaska`,
  [k.BSKJFH_KSJLHFVKLFSJB]: `bskjfh ksjlhfvklfsjb`,
  [k.CPQWHG_SKUGBHOWSFVJH]: `cpqwhg skugbhowsfvjh`,
  [k.DWQEFHKDVB_LASHDVSDALN_SJBVKSF]: `dwqefhkdvb lashdvsdaln sjbvksfjbv`,
  [k.SDV_ASODJHVSF_SLDHGVKSUFGV]: `sdv ;asodjhvsf sldhgvksufgv`,
  [k.KJSBFV]: `kjsbfv`,
  [k.POWQJIF]: `powqjif`,
  [k.XCUYWE]: `xcuywe`,
  [k.POIQRF]: `poiqrf`,
  [k.LABEL]: `label`,
  [k.NONE]: `None`,
  [k.SYSTEM]: `System`,
  [k._3]: `0`,
  [k.CHECKBOX]: `checkbox`,
  [k.LOADING_CHART]: `Loading Chart`,
  [k.ADD_A_CHART]: `+ Add a chart`,
  [k.CREATED_BY]: `Created by:`,
  [k.DELETE_DASHBOARD]: `Delete Dashboard`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET]: `Are you sure you want to delete`,
  [k.PINNED_CHARTS_WILL_NOT_BE_DELE]: `Pinned charts will not be deleted.`,
  [k.CANCEL]: `Cancel`,
  [k.DELETE]: `Delete`,
  [k.DASHBOARD]: `Dashboard`,
  [k.CREATE_NEW]: `+ Create New`,
  [k.REFRESH_DASHBOARD]: `Refresh dashboard`,
  [k.DELETE_DASHBOARD1]: `Delete dashboard`,
  [k.BUTTON]: `button`,
  [k.NO_DASHBOARD_SELECTED]: `No Dashboard selected`,
  [k.SELECT]: `Select`,
  [k.ADDBUTTON]: `addButton`,
  [k.U_B_ADD]: `\u002B Add`,
  [k._4]: ``,
  [k._5]: `-`,
  [k.YOU_ARE_NOT_AUTHORIZED_CLICK]: `You are not authorized. Click here to see details.`,
  [k.ADD]: `add`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_CANCE]: `Are you sure you want to cancel approval? This will unlock the
              checklist fields, change the status and deprecate the previous
              approval.`,
  [k.PLEASE_ENTER_A_COMMENT]: `Please enter a comment`,
  [k.NO]: `No`,
  [k.YES]: `Yes`,
  [k.CANCELLATION]: `Cancellation -`,
  [k.HIDE]: `Hide`,
  [k.SHOW]: `Show`,
  [k.LINE_THROUGH]: `line-through`,
  [k.INCOMPLETE]: `Incomplete`,
  [k.CONFIGURE_AD_HOC_SIGNATURE]: `Configure Ad-hoc Signature`,
  [k.NUMBER_OF_SIGNATORIES]: `Number of signatories`,
  [k.USERS]: `Users`,
  [k.GROUPS]: `Groups`,
  [k.CONFIRM]: `Confirm`,
  [k.REQUEST_APPROVAL]: `Request Approval`,
  [k.ADD_SIGNATURE]: `+ Add Signature`,
  [k.APPLY_YOUR_SIGNATURE]: `Apply your signature`,
  [k.TYPE]: `Type`,
  [k.APPROVAL]: `Approval`,
  [k.REJECTION]: `Rejection`,
  [k.EMAIL_ID]: `Email ID`,
  [k.OTP_SENT_AGAIN_PLEASE_CHECK_Y]: `OTP sent again. Please check your email.`,
  [k.OTP_SENT_TO_THE_MENTIONED_EMAI]: `OTP sent to the mentioned email address.`,
  [k.RESEND]: `Resend`,
  [k.PASSWORD]: `Password`,
  [k.OTP]: `OTP`,
  [k.INCORRECT_OTP]: `Incorrect OTP`,
  [k.GENERATE_OTP]: `Generate OTP`,
  [k.APPROVAL1]: `approval`,
  [k.COMMENT]: `Comment`,
  [k.UPLOAD_YOUR_OWN_SIGNATURE]: `Upload your own signature`,
  [k.ATTACH_FILE]: `Attach File`,
  [k.USE_SYSTEM_GENERATED_SIGNATURE]: `Use system generated signature`,
  [k.CONFIRM_AND_SIGN]: `Confirm and sign`,
  [k.RED]: `red`,
  [k.ENTER_EMAIL]: `Enter email`,
  [k.UPLOAD_NEW]: `Upload new`,
  [k.UPLOAD]: `Upload`,
  [k.BY]: `by:`,
  [k.YOU]: `You`,
  [k.ID]: `ID:`,
  [k.MEMBERS]: `members`,
  [k.MEMBER]: `member`,
  [k.USER]: `user-`,
  [k.GROUP]: `group-`,
  [k.REMOVE_SELECTED_USER]: `Remove selected user`,
  [k.LIST]: `list`,
  [k.USER1]: `user`,
  [k.GROUP1]: `group`,
  [k.LINK]: `link`,
  [k.ENTER_USER_S_EMAIL]: `Enter user's email`,
  [k.ADD_NEW_USER]: `Add new user`,
  [k.SELECT_USERS]: `Select users`,
  [k.ENTER_VALID_EMAIL]: `Enter valid email`,
  [k.TOGGLEUSERDROPDOWN]: `toggleUserDropdown`,
  [k.REMOVEUSER]: `removeUser`,
  [k.DECREMENT]: `decrement`,
  [k.NO_USER_SELECTED]: `No user selected`,
  [k.ADD1]: `Add`,
  [k.ENTER_TITLE]: `Enter title`,
  [k.SEARCH]: `Search`,
  [k.PRIVATE]: `Private`,
  [k.LOADING]: `Loading...`,
  [k.CREATE]: `+ Create`,
  [k.REMOVE]: `Remove`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_REMOV]: `Are you sure you want to remove`,
  [k.FROM_THE_CHECKLIST]: `from the checklist?`,
  [k.CONVERSATION]: `conversation`,
  [k.VIEW_ALL_FIELDS]: `View all fields`,
  [k.UPDATE]: `Update`,
  [k._7]: `#`,
  [k.NO_TITLE]: `No title`,
  [k.MAKE_AS_CURRENT_REVISION]: `Make as current revision`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_MAKE]: `Are you sure you want to make`,
  [k.AS_THE_CURRENT_REVISION]: `as the current revision?`,
  [k.CURRENT_REVISION]: `Current revision`,
  [k.CURRENTREVISION]: `currentRevision`,
  [k.CURRENT]: `Current`,
  [k.MAKE_CURRENT_REVISION]: `Make current revision`,
  [k.MAKE_CURRENT]: `Make current`,
  [k.VIEWING_NOW]: `Viewing now`,
  [k.NO_TITLE1]: `No Title`,
  [k.DONE]: `Done`,
  [k.MORE]: `...more`,
  [k.UNIFIZE_UNIFIZEBLUE]: `unifize_unifizeBlue`,
  [k.CREATE_NEW1]: `Create new`,
  [k.DOCUMENT]: `document`,
  [k.ENTER_TITLE1]: `Enter Title`,
  [k.NOTE_YOUR_DOCUMENT_WILL_BE_SH]: `Note: Your document will be shared with all participants of this
            conversation`,
  [k.CREATE1]: `Create`,
  [k.FILEICONS]: `fileIcons`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET1]: `Are you sure you want to delete "`,
  [k.INLINE_BLOCK]: `inline-block`,
  [k._9]: `.`,
  [k._10]: `"`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_REMOV1]: `Are you sure you want to remove this file?`,
  [k.REMOVE_FROM_CHECKLIST]: `Remove from checklist`,
  [k.FROM_UNIFIZE_FILE_STORE]: `From Unifize file store`,
  [k.UPLOADING_FILE]: `Uploading file...`,
  [k.OTHERS]: `Others`,
  [k.PREVIEW]: `preview`,
  [k.EDIT]: `edit3`,
  [k.FILECONTAINER]: `fileContainer`,
  [k.FILENAME]: `fileName`,
  [k._11]: `|`,
  [k.UPLOADED_ON]: `Uploaded on:`,
  [k.NO_PREVIEW_AVAILABLE]: `No Preview Available`,
  [k.CHECKLIST]: `checklist`,
  [k.PICK_SOURCE]: `Pick Source`,
  [k.UNIFIZE_FILE_STORE]: `Unifize file store`,
  [k.COMPUTER]: `Computer`,
  [k.NO_SOURCE_SELECTED]: `No source selected`,
  [k._12]: `,`,
  [k.SELECT_FORM]: `+ Select form`,
  [k.AUTO_SELECT_ALL_REVISIONS]: `Auto-select all revisions`,
  [k.SELECT_MANUALLY]: `Select manually`,
  [k.BACK]: `Back`,
  [k.AUTO_SELECT_ALL_REVISIONS_IS]: `Auto-select all revisions is`,
  [k.EDIT_SELECTED_REVISION_S]: `Edit selected revision(s)`,
  [k.ALL_REVISIONS]: `All Revisions`,
  [k.SELECT_REVISIONS]: `Select Revisions`,
  [k.REMOVE_LINK_OR_REASSIGN_REVISI]: `Remove link or reassign revisions?`,
  [k.THIS_CONVERSATION_HAS_MULTIPLE]: `This conversation has multiple revisions. Do you want remove the
                link to all of them?`,
  [k.REASSIGN_REVISIONS]: `Reassign Revisions`,
  [k.REMOVE_LINK]: `Remove link`,
  [k.ADDCONVERSATIONBUTTON]: `addConversationButton`,
  [k.AUTO_SELECT_CURRENT_REVISION]: `Auto-select current revision`,
  [k.AUTO_SELECT_CURRENT_REVISION_I]: `Auto-select current revision is`,
  [k.EDIT_SELECTED_REVISION]: `Edit selected revision`,
  [k.SELECT_REVISION]: `Select Revision`,
  [k.NEW_ITEM]: `new item`,
  [k.ITEM]: `item "`,
  [k.SELECT_CHECKLIST_FIELDS]: `Select checklist fields`,
  [k.CREATE_VERSION]: `Create version`,
  [k.CREATE_NEW_REVISION]: `Create new revision`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_CREAT]: `Are you sure you want to create a revision?`,
  [k.CANCELLERS]: `cancellers`,
  [k.ALLOWED_CANCELLERS]: `Allowed Cancellers`,
  [k.ALLOWED_SIGNATORIES]: `Allowed Signatories`,
  [k.ROLES]: `Roles`,
  [k.FIELDS]: `Fields`,
  [k.SPECIFIC_USERS]: `Specific users`,
  [k.SELECT_THE_DATA_TO_COPY_TO_NEW]: `Select the data to copy to new revision`,
  [k.CHECKLIST_AND_FORM_DATA]: `Checklist and form data`,
  [k.SELECT_FIELDS]: `Select fields`,
  [k.REVISION_NOTES]: `Revision notes`,
  [k.WHY_ARE_YOU_MAKING_THIS_REVISI]: `Why are you making this revision?`,
  [k.CREATE_REVISION]: `Create revision`,
  [k.REVISION]: `Revision`,
  [k.CREATE_REVISION1]: `Create Revision`,
  [k.CONVERSATION1]: `Conversation`,
  [k.NO_MATCHING_RESULTS]: `no matching results.`,
  [k.ADD_NEW_USER1]: `+ Add new user`,
  [k.CHECKLISTREMOVE]: `checklistRemove`,
  [k.UPLOAD_NEW_VERSION]: `Upload New Version`,
  [k.DOWNLOAD]: `Download`,
  [k.MMM_DD_YYYY]: `MMM DD, YYYY`,
  [k.NOT_ASSIGNED]: `Not Assigned`,
  [k.SEND_AN_EMAIL_TO_CONVERSATION]: `Send an email to conversation`,
  [k.FILES]: `Files`,
  [k.DATA]: `Data`,
  [k.INFO]: `Info`,
  [k.CHECKLIST1]: `Checklist`,
  [k.JSON_FOR_ADOBE]: `JSON for Adobe`,
  [k.JSON_FOR_PDFGENERATORAPI]: `JSON for PDFGeneratorAPI`,
  [k.SAVE]: `Save`,
  [k.REFRESH]: `Refresh`,
  [k.TO_SEE_LATEST_CHECKLIST_DATA]: `to see latest checklist data`,
  [k.CREATOR]: `Creator`,
  [k.DATE_CREATED]: `Date Created`,
  [k.MMM_DD_YYYY_HH_MM_A]: `MMM DD, YYYY hh:mm a`,
  [k.AGE]: `Age`,
  [k.DAYS]: `days`,
  [k.DAYS1]: `Days`,
  [k.PARENT1]: `Parent:`,
  [k.CHILD]: `, Child:`,
  [k.LEAVE_CONVERSATION]: `Leave Conversation`,
  [k.PUBLIC]: `Public`,
  [k.HIDE_CHAT_CONTENT]: `Hide chat content`,
  [k.HIDE_EVERYTHING]: `Hide everything`,
  [k.ADD_NEW_FIELDS]: `Add new fields`,
  [k.PRIVACY_SETTINGS]: `Privacy Settings`,
  [k.NORMAL]: `Normal`,
  [k.MAKE_PRIVATE]: `Make Private`,
  [k.ONLY_PARTICIPANTS_CAN_ACCESS_T]: `Only participants can access these conversations`,
  [k.HIDE_ONLY_CHAT_CONTENT]: `Hide only chat content`,
  [k.ONLY_THE_CONTENTS_OF_THE_CONVE]: `Only the contents of the conversation will be hidden. The
              conversation title, status and owner will be visible to other
              users.`,
  [k.CONVERSATIONS_WILL_BE_VISIBLE]: `Conversations will be visible only to the participants, admin(s),
              and whitelisted users.`,
  [k.REMINDERS]: `reminders`,
  [k.REMINDERS1]: `Reminders`,
  [k.WHATSAPP]: `whatsapp`,
  [k.ON]: `On`,
  [k.OFF]: `Off`,
  [k.LEFTHANDLE]: `leftHandle`,
  [k.DOWNLOAD_PROHIBITED]: `Download prohibited`,
  [k.DOWNLOADING_FILES_IS_PROHIBITE]: `Downloading files is prohibited by the admin.`,
  [k.WE_RE_SORRY_SOMETHING_S_GONE]: `We're sorry — something's gone wrong.`,
  [k.OUR_TEAM_HAS_BEEN_NOTIFIED_BU]: `Our team has been notified, but`,
  [k.LET_US_KNOW]: `let us know`,
  [k.WHAT_HAPPENED]: `what happened.`,
  [k.BROWSER]: `Browser:`,
  [k.VERSION]: `Version:`,
  [k.UID]: `UID:`,
  [k.URL]: `URL:`,
  [k.ORG]: `Org:`,
  [k.TRY_AGAIN]: `Try again`,
  [k.CHROME]: `Chrome`,
  [k.FIREFOX]: `Firefox`,
  [k.MICROSOFT_INTERNET_EXPLORER]: `Microsoft Internet Explorer`,
  [k.SAFARI]: `Safari`,
  [k.EDGE]: `Edge`,
  [k.OPERA]: `Opera`,
  [k.COULD_NOT_FETCH_CHAT_ROOMS]: `Could not fetch chat rooms:`,
  [k.RETRY]: `Retry`,
  [k._13]: `+`,
  [k.SCROLLCOLUMN_D]: `scrollColumn2d`,
  [k.HOME]: `Home`,
  [k.WELCOME_BACK]: `Welcome back!`,
  [k.LOADING_USER]: `Loading user...`,
  [k.YOUR_HOME_SCREEN_HAS_NOT_BEEN]: `Your home screen has not been set up by your org admin.`,
  [k.UNIFIZE_HOME_NOT_SET_UP]: `Unifize home not set up`,
  [k.CONTACT_YOUR_ORG_ADMIN_TO_SET]: `Contact your org admin to set up your Unifize home`,
  [k.HERE_S_WHAT_S_GOING_ON_AT]: `Here's what's going on at`,
  [k.CLOSE1]: `Close`,
  [k.SKIP_INVITE]: `Skip Invite`,
  [k.DO_NOT_ADD_CREATOR_AS_OWNER]: `Do not add creator as owner`,
  [k.DO_NOT_ADD_CREATOR_AS_PARTICIP]: `Do not add creator as participant`,
  [k.DO_NOT_SEND_CONFIRMATION_TO_SE]: `Do not send confirmation to sender`,
  [k.DISABLE_AUTO_NUMBER]: `Disable auto number`,
  [k.CONVERT_TO_FEED]: `Convert to feed`,
  [k.SLA_IN_DAYS_AUTO_FILL_DUE_DAT]: `SLA in days (Auto fill due date when conversation is created)`,
  [k.EMAILS_SENT_HERE_WILL_START_A]: `Emails sent here will start a new instance`,
  [k.DO_NOT_SHOW_THIS_PROCESS_IN]: `Do not show this process in + NEW`,
  [k.DO_NOT_INVITE_SENDER_TO_CONVER]: `Do not invite sender to conversation`,
  [k.NEXT_COUNT]: `Next Count`,
  [k.NOTIFY_GROUPS]: `Notify Groups`,
  [k.ENABLE_PAGINATION_IN_PROCESS_T]: `Enable pagination in process table`,
  [k.ENABLE_PAGINATION_IN_REPORTS]: `Enable pagination in reports`,
  [k.ALLOW_ADHOC_ARCHIVING]: `Allow adhoc archiving`,
  [k.ADD_FIELD]: `Add field`,
  [k.FIELD1]: `Field`,
  [k.DOWNLOAD_SAMPLE_CSV]: `Download sample CSV`,
  [k.ADD_FIELD1]: `Add Field`,
  [k.BEHAVIOR]: `Behavior`,
  [k.MANDATORY_IF_NOT_HIDDEN]: `Mandatory if not hidden`,
  [k.VALUE]: `Value`,
  [k.CONDITION]: `Condition`,
  [k.FORM_DATA]: `FORM DATA`,
  [k.CHECKLIST_DATA]: `CHECKLIST DATA`,
  [k.CONVERSATION_DATA]: `CONVERSATION DATA`,
  [k.SHOW_UNTIL_CONDITION_MET]: `Show until condition met`,
  [k.HIDDEN_UNTIL_CONDITION_MET]: `Hidden until condition met`,
  [k.MANDATORY_UNTIL_CONDITION_MET]: `Mandatory until condition met`,
  [k.DISABLE_UNTIL_CONDITION_MET]: `Disable until condition met`,
  [k.COPY]: `Copy`,
  [k.ADD_CONDITION_AND]: `+ Add condition (AND)`,
  [k.ADD_NEW_CONDITION]: `+ Add New Condition`,
  [k.DELETE_FIELD]: `Delete field`,
  [k._17]: `?`,
  [k.FIELDNAMEINPUT]: `fieldNameInput-`,
  [k.FIELDSETTINGS]: `Field Settings`,
  [k.FORM]: `form`,
  [k.NOT_ALLOWED]: `not-allowed`,
  [k.OLDSETTINGS]: `oldSettings`,
  [k.PROMPT_WHOM]: `Prompt whom`,
  [k.WHEN]: `When`,
  [k.CREATE_RULES_TO_PROMPT_USERS_T]: `Create rules to prompt users to complete this step. Specified users
          will view these fields inside the conversations.`,
  [k.CREATE_NEW_RULE]: `Create new rule`,
  [k.AUTO_ARCHIVE]: `Auto archive`,
  [k.SEND_MESSAGE]: `Send Message`,
  [k.CHANGE_STATUS_TO]: `Change status to`,
  [k.ADD_PARTICIPANTS]: `Add participants`,
  [k.REMOVE_PARTICIPANTS]: `Remove participants`,
  [k.UPDATE_PRIVACY]: `Update Privacy`,
  [k.WHITELIST]: `Whitelist`,
  [k.DON_T_CHANGE]: `Don't change`,
  [k.MAKE_PUBLIC]: `Make Public`,
  [k.UNARCHIVE]: `Unarchive`,
  [k.IS_NOT_SUPPORTED]: `is not supported`,
  [k.GET_SIGNATURE_OF_ANY]: `Get signature of any`,
  [k.USER_S]: `user(s)`,
  [k.SELECT_AD_HOC]: `Select ad-hoc`,
  [k.WHO_CAN_CANCEL]: `Who can cancel`,
  [k.WHEN_CAN_THEY_CANCEL]: `When can they cancel`,
  [k.HIDE_WHEN_INACTIVE]: `Hide when inactive`,
  [k.DISABLE_WHEN_INACTIVE]: `Disable when inactive`,
  [k.CONTINUE_TO_LOCK_FIELDS_UNDER]: `Continue to lock fields under previous approval`,
  [k.LOCK_CHECKLIST_FIELDS_DURING_A]: `Lock checklist fields during and post approval/rejection:`,
  [k.LOCK_STATUS_PREVENT_USERS_FRO]: `Lock status (prevent users from changing status)`,
  [k.CHANGE_OWNER_TO]: `Change owner to`,
  [k.SEND_A_MESSAGE]: `Send a message`,
  [k.MARK_REVISION_AS_CURRENT]: `Mark revision as current`,
  [k.CANCEL_ALL_PREVIOUS_APPROVALS]: `Cancel all previous approvals (within hierarchy)`,
  [k.SELECT_CHECKLIST_FIELD]: `Select checklist field`,
  [k.DELETED_FIELD]: `Deleted field`,
  [k.PROCESS]: `Process`,
  [k.EMBEDDED_FIELDS]: `EMBEDDED FIELDS`,
  [k.ADD_EMBEDED_FIELD]: `Add Embeded field`,
  [k.ONLY_ALLOW_CREATING_NEW_CONVER]: `Only allow creating new conversations`,
  [k.ONLY_ALLOW_SELECTING_EXISTING]: `Only allow selecting existing conversations`,
  [k.ALLOW_BOTH_SELECTING_AND_CREAT]: `Allow both selecting and creating`,
  [k.ALLOW_MULTIPLE]: `Allow multiple`,
  [k.ALLOW_ARCHIVED_CONVERSATIONS]: `Allow archived conversations`,
  [k.SHOW_FIELD_NAMES_IN_PREVIEW]: `Show field names in preview`,
  [k.SHOW_STATUS_DUE_DATE]: `Show status, due date`,
  [k.AUTO_FILL_RELATED_CONVERSATION]: `Auto fill related conversation`,
  [k.SUPRESS_TITLE_HYPERLINK_TO_THE]: `Supress title hyperlink to the record`,
  [k.SORTBY]: `SortBy:`,
  [k.CREATED_DATE]: `Created date`,
  [k.ADDED_DATE]: `Added Date`,
  [k.SELECT_EXISTING_BY]: `Select existing by`,
  [k.TITLE]: `Title`,
  [k.PLACEHOLDER]: `Placeholder`,
  [k.ALLOW_OFFICE_INTEGRATION]: `Allow Office 365 integration`,
  [k.YOU_MUST_SELECT_A_DEFAULT_FOLD]: `You must select a default folder on sharepoint/office365 to upload or
          create files.`,
  [k.AUTHENTICATE_AND_SELECT_FOLDER]: `Authenticate and Select Folder`,
  [k.VIEW]: `View`,
  [k.FOLDER]: `Folder`,
  [k.CONSUMPTION_OPTIONS]: `Consumption options:`,
  [k.ALLOW_SINGLE]: `Allow single`,
  [k.PREVIEW_OPTIONS]: `Preview options:`,
  [k.SHOW_FULL_PREVIEW]: `Show full preview`,
  [k.SORT_BY]: `Sort by:`,
  [k.DATE]: `Date`,
  [k.NAME]: `Name`,
  [k.DEFAULT_FILE]: `Default file:`,
  [k.SUPPRESS_NOTIFICATIONS_ON_DEFA]: `Suppress notifications on default values`,
  [k.FILE_UPLOAD_OPTIONS]: `File upload options:`,
  [k.UPLOAD_FROM_COMPUTER]: `Upload from computer`,
  [k.OFFICE_ONEDRIVE_SHAREPOI]: `Office 365 OneDrive / Sharepoint`,
  [k.FOLDER_PATH]: `Folder path`,
  [k.THE_CREATED_FILE_CAN_BE_EDITED]: `The created file can be edited by chatroom members`,
  [k.ADD_A_FORM]: `+ Add a form`,
  [k.AUTO_FILL_RELATED_CONVERSATION1]: `Auto fill related conversations`,
  [k.SHOW_COMPRESSED_PREVIEW]: `Show compressed preview`,
  [k.SHOW_STATUS_OWNER_AND_DUE_DAT]: `Show status, owner and due date`,
  [k.SUPPRESS_TITLE_HYPERLINK_TO_TH]: `Suppress title hyperlink to the record`,
  [k.LINKED_FIELD_RELATION]: `Linked field relation:`,
  [k.CHILD1]: `child`,
  [k.PARENT2]: `Parent`,
  [k.CHILD2]: `Child`,
  [k.OTHER_OPTIONS]: `Other options:`,
  [k.ALLOW_ONLY_SELECTING]: `Allow only selecting`,
  [k.ALLOW_SELECTING_AND_CREATING]: `Allow selecting and creating`,
  [k.ALLOW_ONLY_CREATING]: `Allow only creating`,
  [k.ONLY_SHOW_LINKED_FIELDS]: `Only show linked fields`,
  [k.REVISIONS]: `Revisions:`,
  [k.ALLOW_MANUAL_REVISION_LINKING]: `Allow manual revision linking even when`,
  [k.THERE_ARE_NO_REVISIONS]: `there are no revisions`,
  [k.CREATED]: `created`,
  [k.MAX]: `Max`,
  [k.MIN]: `Min`,
  [k.MAX_VALUE_SHOULD_BE_MORE_THAN]: `Max value should be more than minimum value.`,
  [k.STEP]: `Step`,
  [k.MINIMUM_PLACES_TO_DISPLAY_AFTE]: `Minimum places to display after decimal point (Show trailing
              zeroes even if the user enters less digits than specified)`,
  [k.IF_THE_USER_ENTERS_MORE_DIGITS]: `If the user enters more digits after the decimal point than
            specified above:`,
  [k.DON_T_ROUND_OFF_OR_TRUNCATE]: `Don't round off or truncate`,
  [k.ROUND_OFF_WHEN_DISPLAYING_BUT]: `Round-off when displaying, but store all digits as entered`,
  [k.DEFAULT_VALUE]: `Default Value`,
  [k.SUPPRESS_NOTIFICATIONS_FOR_DEF]: `Suppress notifications for default values`,
  [k.TYPE1]: `TYPE`,
  [k.UNIFIZEPDF]: `unifizePdf`,
  [k.UNIFIZE_US]: `unifize.us`,
  [k.DELETED_FIELD1]: `(Deleted field)!`,
  [k.CHECKLIST_FIELD_WHERE_NATIVE_F]: `CHECKLIST FIELD WHERE NATIVE FILE IS LOCATED`,
  [k.UPLOAD_TEMPLATE]: `UPLOAD TEMPLATE`,
  [k.DOWNLOAD1]: `download`,
  [k.FETCHING]: `fetching`,
  [k.UPLOAD_TEMPLATE1]: `Upload Template`,
  [k.CLICK_ON_STITCH_TO_CREATE_A_P]: `(Click on Stitch to create a PDF)`,
  [k.STITCH]: `+ Stitch`,
  [k.BUTTON_TEXT_OPTIONAL]: `Button Text (Optional)`,
  [k.NAME_OF_SAVED_REPORTS_OPTIONA]: `Name of saved reports (Optional)`,
  [k.PREVIEW_OPTIONS1]: `Preview Options`,
  [k.UPLOAD_TO_ANOTHER_FILE_FIELD]: `Upload to another file field:`,
  [k.DON_T_ATTACH_FILE_TO_CHECKLIST]: `Don't attach file to checklist`,
  [k.ATTACH_LATEST_VERSION_ONLY]: `Attach latest version only`,
  [k.ATTACH_ALL_VERSIONS]: `Attach all versions`,
  [k.SORT_BY1]: `Sort by`,
  [k.DATE_ADDED]: `Date added`,
  [k.SEARCH_USERS]: `Search users`,
  [k.OPEN_BY_DEFAULT_FOR]: `Open by default for`,
  [k.OPTIONS]: `Options`,
  [k.ADD_OPTIONS_DYNAMICALLY]: `Add Options Dynamically`,
  [k.DEFAULT1]: `Default`,
  [k.ALPHABETICAL]: `Alphabetical`,
  [k.PENDINGVALUE]: `pendingValue`,
  [k.COMPLETEDVALUE]: `completedValue`,
  [k.SELECT_A_STATUS]: `Select a Status`,
  [k.TRIANGLEDOWN]: `triangleDown`,
  [k.MULTILINE]: `Multiline`,
  [k.USER2]: `User`,
  [k.MULTIPLE]: `Multiple`,
  [k.SELECT_A_FORM_TO_ADD_CONDITION]: `Select a form to add conditions`,
  [k.UNSTYLED]: `unstyled`,
  [k.D_AUTO_LAYOUT]: `2D Auto Layout`,
  [k.MAXIMUM_FIELD_WIDTH_SMALLER_W]: `Maximum field width (smaller widths allow more fields on a row)`,
  [k.FORCE_START_ON_A_NEW_ROW]: `Force start on a new row`,
  [k.X]: `1x`,
  [k.X1]: `2x`,
  [k.X2]: `3x`,
  [k.MAX_WIDTH]: `Max Width`,
  [k.CONDITIONS]: `Conditions`,
  [k.PROMPTS]: `Prompts`,
  [k.LAYOUT]: `Layout`,
  [k.FORM_CONDITIONS]: `Form Conditions`,
  [k.CANCEL_CHANGES]: `Cancel Changes`,
  [k.UPDATE_FIELD_SETTINGS]: `Update Field Settings`,
  [k.RESOLVE_AND_UPDATE]: `Resolve and update`,
  [k.ME1]: `Me`,
  [k.ADDFIELDS]: `addFields`,
  [k.HOLLOWPLUSCIRCLE]: `hollowPlusCircle`,
  [k.ADD_FIELDS]: `Add Fields`,
  [k.THE_CHECKLIST_HAS_NOT_BEEN_CON]: `The checklist has not been configured yet.`,
  [k.USE_THE_ADD_FIELD_BUTTON_TO_ST]: `Use the Add Field button to start creating or upload an excel
              sheet.`,
  [k.CHECKLIST_LAYOUT_DEFAULTS]: `Checklist Layout Defaults`,
  [k.CHECKLIST_OPEN_BY_DEFAULT]: `Checklist open by default?`,
  [k.WEB_APP]: `Web App`,
  [k.UNIFIZE_LITE_ON_WEB]: `Unifize Lite on Web`,
  [k.UNIFIZE_LITE_ON_MOBILE_NAVIGA]: `Unifize Lite on Mobile (Navigate to checklist when opening
            conversation)`,
  [k.DEFAULT_CHECKLIST_WIDTH_ON_UNI]: `Default checklist width on Unifize web app`,
  [k.SINGLE_COLUMN]: `Single Column`,
  [k.DOUBLE_COLUMN]: `Double Column`,
  [k.TRIPLE_COLUMN]: `Triple Column`,
  [k.SPLIT_AVAILABLE_SCREEN_SPACE_I]: `Split available screen space into 50% conversation and 50% checklist`,
  [k.ONLY_THE_CONTENTS_OF_THE_CONVE1]: `Only the contents of the conversation will be hidden, other data
            like notifications will be visible to all`,
  [k.CONVERSATIONS_WILL_BE_VISIBLE1]: `Conversations will be visible only to the participants, admin and
            whitelisted people`,
  [k.PRIVACY_SETTINGS_DON_T_APPLY_T]: `Privacy settings don't apply to whitelisted people`,
  [k.MAKE_PRIVATE1]: `Make private`,
  [k.ONLY_PARTICIAPNTS_CAN_ACCESS_T]: `Only particiapnts can access these conversations`,
  [k.PRIVATE_CONVERSATIONS_CAN_ONLY]: `Private conversations can only be edited by the Process Owner`,
  [k.AUTO_REMIND_IN]: `Auto remind in`,
  [k.ALLOW_PARTICIPANTS_TO_DISABLE]: `Allow participants to disable`,
  [k.REMINDER_MESSAGE]: `Reminder message`,
  [k.SELECT_VALUE]: `Select value`,
  [k.ADD_AUTO_REMINDER]: `Add auto reminder`,
  [k.DESCRIPTION]: `Description`,
  [k.PARTICIPANTS]: `Participants`,
  [k.OWNER]: `Owner`,
  [k.FORM_OWNERS]: `Form owners`,
  [k.PROCESS_OWNERS]: `Process owners`,
  [k.TAB]: `Tab`,
  [k.NOTIFICATION_SETTINGS]: `Notification Settings`,
  [k.EXIT_PROCESS_EDITOR]: `Exit Process Editor`,
  [k.ENTER_A_TITLE_FOR_THIS_PROCESS]: `Enter a title for this process`,
  [k.SAVEPROCESSTEMPLATEBUTTON]: `saveProcessTemplateButton`,
  [k.DEFINE_NEW_PROCESS_TEMPLATE]: `Define New Process Template`,
  [k.CREATE_PROCESS]: `Create Process`,
  [k.SAVE_CHANGES]: `Save Changes`,
  [k.EXPORT_FIELDS_JSON]: `Export fields JSON`,
  [k.SAVE_AND_GO_TO]: `Save and go to`,
  [k.TEAM]: `Team`,
  [k.STATUS]: `Status`,
  [k.DELETED_FIELDS]: `Deleted Fields`,
  [k.ADVANCED_PROCESS_SETTINGS]: `Advanced Process Settings`,
  [k.DEFAULT_FORM_COLOUR]: `Default form colour`,
  [k.DETAILS]: `Details`,
  [k.EXIT_FORM_EDITOR]: `Exit Form Editor`,
  [k.CREATE_FORM]: `Create Form`,
  [k.REPORTS]: `Reports`,
  [k.FORMS]: `Forms`,
  [k._18]: `>`,
  [k.TO]: `to`,
  [k.FILTERS_APPLIED]: `Filters applied:`,
  [k.SORTED_BY]: `Sorted by:`,
  [k.CLEAR_ALL]: `Clear All`,
  [k.SHARE]: `Share`,
  [k.RESULTS]: `Results`,
  [k.SAVE_AS]: `SAVE AS`,
  [k.ASCENDING]: `Ascending`,
  [k.DESCENDING]: `Descending`,
  [k.TO_ARRANGE_DRAG_UP_OR_DOWN]: `To arrange drag up or down`,
  [k.FILTER_NAME]: `Filter Name`,
  [k.ORDER]: `Order`,
  [k.PARAMETERS]: `Parameters`,
  [k.SORT_BY2]: `Sort By`,
  [k.FILTER_VALUES]: `Filter Values`,
  [k.SELECT_DATE]: `Select date`,
  [k.FILTER_BY]: `Filter by`,
  [k.AFTER]: `After`,
  [k.BEFORE]: `Before`,
  [k.FILTER]: `Filter`,
  [k.USERFILTER]: `userFilter`,
  [k.THERE_ARE_NO_FORMS_THAT_MATCHE]: `There are no forms that matches the filters`,
  [k._19]: `_`,
  [k.DELETE_FORM]: `Delete form`,
  [k.FORM_NAME]: `Form Name`,
  [k.CREATED_AT]: `Created At`,
  [k.UPDATED_AT]: `Updated At`,
  [k.CREATED_BY1]: `Created By`,
  [k.NEW]: `NEW`,
  [k.MANAGE]: `Manage`,
  [k.PROCESSES]: `Processes`,
  [k.USER_ANALYTICS1]: `User Analytics`,
  [k.EDIT1]: `Edit`,
  [k.DESCRIPTION1]: `Description:`,
  [k.REPORT]: `Report:`,
  [k.CREATE_NEW_CHART]: `Create new chart`,
  [k.CHARTS_FOR_THIS_REPORT]: `Charts for this report`,
  [k.MULTIPLE_VALUE_CALCULATION]: `Multiple Value Calculation`,
  [k.IF_A_FIELD_CONTAINS_MULTIPLE_V]: `If a field contains multiple values per conversation, select what
            the y-axis totals represent`,
  [k.Y_AXIS_SHOWS_TOTAL_CONVERSATIO]: `Y-axis shows total conversation count (i.e. proportional weight
                to each other)`,
  [k.Y_AXIS_SHOWS_TOTAL_VALUE_COUNT]: `Y-axis shows total value count (i.e. equal weight to each value)`,
  [k.PRIMARY_Y_AXIS_LABEL]: `Primary Y-axis label`,
  [k.SECONDARY_Y_AXIS_LABEL]: `Secondary Y-axis label`,
  [k.CHART_TYPE]: `Chart Type`,
  [k.PRIMARY_FIELD]: `Primary field`,
  [k.PRIMARYRECORD]: `primaryRecord`,
  [k.PRIMARY_RECORD]: `Primary Record`,
  [k.MULTIPLE_VALUES]: `Multiple Values`,
  [k.ADD_COMPARISON]: `+ Add Comparison`,
  [k.ADD_TRENDLINE]: `Add Trendline`,
  [k.SELECT_ABOVE_FIELDS_TO_ENABLE]: `Select above fields to enable this`,
  [k.TRENDLINE_LABEL]: `Trendline label`,
  [k.TRENDLINE_COLOR]: `Trendline Color`,
  [k.HIDE1]: `hide`,
  [k.OF]: `of`,
  [k.SELECT_FIELD]: `Select Field`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET2]: `Are you sure you want to delete "`,
  [k._20]: `"`,
  [k.GROUP_BY]: `Group By`,
  [k.ADD_TREND_LINE]: `Add Trend Line`,
  [k.PERCENT]: `Percent`,
  [k.PPM]: `PPM`,
  [k.SHOW_AS_PERCENT_OF_OTHER_VALUE]: `Show as percent of other value`,
  [k.SERIES1]: `Series #`,
  [k.SUMMARY_TYPE]: `Summary Type`,
  [k.X_AXIS]: `x-axis`,
  [k.UNTITLED_CHART]: `Untitled Chart`,
  [k.ADVANCED_SETTINGS]: `Advanced Settings`,
  [k.PLEASE_FILL_MANDATORY_FIELDS]: `Please fill mandatory fields`,
  [k.SUBMIT]: `submit`,
  [k.CHART1]: `Chart`,
  [k.SELECT_A_COMPARISON_CHART]: `Select a Comparison Chart`,
  [k.FORM1]: `(FORM:`,
  [k.FIELD2]: `) (Field:`,
  [k.COMMONCHECKLIST]: `commonChecklist`,
  [k.MULTIPLE_VALUE_FIELD]: `MULTIPLE VALUE FIELD`,
  [k.STATUS1]: `status`,
  [k.CREATED1]: `Created`,
  [k.COMPLETED]: `Completed`,
  [k.ADD_COLUMN]: `Add Column`,
  [k.SELECT_FORM1]: `Select form`,
  [k.THE_COUNT_WILL_REPRESENT_THE_T]: `The count will represent the total of all the instances of this form`,
  [k.X_AXIS1]: `(x-axis)`,
  [k.PRIMARY_FIELDS_ARE_THE_MAIN_UN]: `Primary fields are the main underlying data for a chart`,
  [k.GROUP_BY_FIELD]: `Group by field`,
  [k.OPTIONAL]: `(Optional)`,
  [k.GROUP_BY_FIELD_TYPE_IS_APPLIED]: `Group by field type is applied on primary fields to create a chart`,
  [k.ADVANCED_SETTINGS1]: `Advanced settings`,
  [k.IF_A_FIELD_CONTAINS_MULTIPLE_V1]: `If a field contains multiple values per conversation, select what
              the y-axis totals represent`,
  [k.Y_AXIS_LABEL]: `Y-axis label`,
  [k.SAVE_CHANGES1]: `Save changes`,
  [k.CREATE_CHART]: `Create chart`,
  [k.LABEL_FOR_Y_AXIS_OF_THE_CHART]: `Label for Y-axis of the chart`,
  [k.IF_A_FIELD_CONTAINS_MULTIPLE_V2]: `If a field contains multiple values per conversation, select what the
          y-axis totals represent`,
  [k.CREATE_AND_SAVE]: `Create and save`,
  [k.Y_AXIS_SHOWS_TOTAL_CONVERSATIO1]: `Y-axis shows total conversation count (i.e. proportional weight to
          each value)`,
  [k.ENTER_THE_TITLE_OF_YOUR_CHART]: `Enter the title of your chart`,
  [k.NUMERIC_CHECKLIST_FIELD]: `Numeric checklist field`,
  [k.THIS_FIELD_WILL_CONTAIN_THE_NU]: `This field will contain the numerical data to add, like $ values,
          total time, or units`,
  [k.IF_A_FIELD_CONTAINS_MULTPLE_VA]: `If a field contains multple values per conversation, select what
              the y-axis totals represent`,
  [k.DAILY]: `Daily`,
  [k.WEEKLY]: `Weekly`,
  [k.MONTHLY]: `Monthly`,
  [k.YEARLY]: `Yearly`,
  [k.MMM_DD_YYYY1]: `MMM DD YYYY`,
  [k.LAST]: `Last`,
  [k.TIME_INTERVAL_FREQUENCY]: `Time interval (Frequency)`,
  [k.THE_INTERVAL_AT_WHICH_THE_NUME]: `The interval at which the numerical value selected above will repeat`,
  [k.TIME_PERIOD]: `Time period`,
  [k.SELECT_A_TIME_FRAME_OR_A_DATE]: `Select a time frame or a date range for the chart`,
  [k.COLUMN1]: `Column`,
  [k.SHOW_A_COLUMN_FOR_EACH_OF_THE]: `Show a column for each of the following:`,
  [k.FIELDS_OF_THE_CHART]: `Fields of the chart`,
  [k.CHART_TITLE]: `Chart title`,
  [k.WHAT_DOES_THIS_CHART_REPRESENT]: `What does this chart represent?`,
  [k.SELECT_A_CHART_FOR]: `Select a chart for`,
  [k.SAVE_AS1]: `Save As`,
  [k.DO_YOU_WANT_TO_DELETE]: `Do you want to delete "`,
  [k.ENTER_REPORT_TITLE]: `Enter Report Title`,
  [k.SAVE_AS_REPORT]: `Save As Report`,
  [k.EDIT_REPORT_TITLE]: `Edit Report Title`,
  [k.EDIT2]: `edit`,
  [k.REPORT_NAME]: `Report Name`,
  [k.STRING1]: `String`,
  [k.APPLY_BULK_CHANGES]: `Apply bulk changes`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_APPLY]: `Are you sure you want to apply changes to multiple conversations?`,
  [k.REPEAT_LAST_ACTION_TO_ALL]: `Repeat last action to all`,
  [k.REPLACE_ALL]: `Replace all`,
  [k.ALL]: `All`,
  [k.RECORDS]: `records`,
  [k.DATE1]: `date`,
  [k.CUSTOMIZE_VIEW]: `Customize View`,
  [k.DOWNLOAD_AS_CSV]: `Download as CSV`,
  [k.DOWNLOAD_AS_XLSX]: `Download as XLSX`,
  [k.DOWNLOAD_WITH_FILES]: `Download with files`,
  [k.DOWNLOAD_AS_JSON_BETA]: `Download as JSON (beta)`,
  [k.YOU_WILL_RECEIVE_AN_EMAIL_ON]: `You will receive an email on`,
  [k.ONCE_THE_IMPORT_IS_DON]: `once
        the import is done.`,
  [k.SUBMIT1]: `Submit`,
  [k.UPLOAD_CSV]: `Upload CSV`,
  [k.SELECT_CSV_FILE]: `Select CSV File`,
  [k.LOADING_RESULTS]: `Loading Results`,
  [k.SHOW_ALL_REVISIONS]: `Show all revisions`,
  [k.NEW1]: `New`,
  [k.CUSTOMIZE_TABLE_VIEW]: `Customize Table View`,
  [k.COLUMN_TITLE]: `Column Title`,
  [k.RESET]: `Reset`,
  [k.APPLY]: `Apply`,
  [k.SAVEAS]: `saveAs`,
  [k.EMBEDDED_FIELDS1]: `Embedded Fields`,
  [k.EXPANSION]: `expansion`,
  [k.FILTER_BY_VALUE]: `Filter by value`,
  [k.MONTHS]: `Months`,
  [k.YEARS]: `Years`,
  [k.DATE_RANGE]: `Date range`,
  [k.FORM2]: `Form`,
  [k.EXPAND]: `Expand`,
  [k.PRIORITY]: `priority`,
  [k.LOW]: `low`,
  [k.NORMAL1]: `normal`,
  [k.HIGH]: `high`,
  [k.CRITICAL]: `critical`,
  [k.SELECT_ALL1]: `Select All`,
  [k.SELECT_NONE]: `Select None`,
  [k.MAPPED_TO]: `Mapped to`,
  [k.PENDING]: `Pending`,
  [k.COMPLETE]: `Complete`,
  [k.INCLUDE]: `Include`,
  [k.ARCHIVED]: `Archived`,
  [k.CANCELLED]: `Cancelled`,
  [k.THERE_ARE_NO_PROCESSES_THAT_MA]: `There are no processes that match these filters`,
  [k.RESIZING]: `Resizing "`,
  [k._24]: "",
  [k.NO_VALUE]: `No Value`,
  [k.CURRENTCHATROOMTITLE]: `currentChatroomTitle`,
  [k.C]: `C`,
  [k.CARETUP]: `caretUp`,
  [k.MAKE_AS_CURRENT]: `Make as current`,
  [k.ARRAY]: `Array`,
  [k.EDITPENCIL]: `editPencil`,
  [k.NO_DESCRIPTION]: `No Description`,
  [k.DISABLED1]: `Disabled`,
  [k.PARENT3]: `PARENT`,
  [k.NO_PARENT]: `No Parent`,
  [k.UPDATE_STATUS]: `UPDATE STATUS:`,
  [k.CARETDOWN]: `caretDown`,
  [k.UNTITLED_CONVERSATION]: `Untitled Conversation`,
  [k.PAGE]: `Page`,
  [k.GO_TO_PAGE]: `| Go to page:`,
  [k.BACK_TO_REPORT]: `Back to Report`,
  [k.CREATE_ANOTHER_CHART]: `Create Another Chart`,
  [k.OWNER1]: `owner`,
  [k.DUEDATE]: `dueDate`,
  [k._28]: `↑`,
  [k._29]: `↓`,
  [k.PROCESS_NAME]: `Process Name`,
  [k.ACTIVE_INSTANCES]: `Active Instances`,
  [k.TOTAL_INSTANCES]: `Total Instances`,
  [k.CREATED_BY2]: `Created by`,
  [k.EDIT_PROCESS]: `Edit Process`,
  [k.DELETE_PROCESS]: `Delete Process`,
  [k.DRAFT]: `DRAFT`,
  [k.DELETE_PROCESS1]: `Delete process`,
  [k.ALL_ASSOCIATED_CONVERSATIONS]: `? All associated conversations will be cancelled.`,
  [k.FETCHING_USER_AND_ORG_DETAILS]: `Fetching user and org details`,
  [k.INTEGRATION_NOT_FOUND]: `Integration not found!`,
  [k.IS_REQUESTING_PERM]: `is requesting
            permission to access your Unifize account on`,
  [k.WHAT_CAN]: `What can`,
  [k.DO]: `do?`,
  [k.ALLOW]: `Allow`,
  [k.DENY]: `Deny`,
  [k.HTTPS_CDN_BFLDR_COM_H_O_W]: `https://cdn.bfldr.com/5H442O3W/at/pl546j-7le8zk-6gwiyo/Slack_Mark.svg?auto=webp&format=svg`,
  [k.HTTPS_NEWS_MICROSOFT_COM_WP]: `https://news.microsoft.com/wp-content/uploads/prod/2022/05/Microsoft-logo_rgb_c-gray-768x344.png`,
  [k.SLACK]: `Slack`,
  [k.MICROSOFT]: `Microsoft 365`,
  [k.MICROSOFT_ADMIN]: `Microsoft 365 Admin`,
  [k.CREATE_CONVERSATIONS_ON_UNIFIZ]: `Create conversations on Unifize on your behalf.`,
  [k.FORWARD_MESSAGES_TO_UNIFIZE_ON]: `Forward messages to Unifize on your behalf.`,
  [k.NETWORK_ERROR_LOOKS_LIKE_YOU]: `Network Error! Looks like you're offline :(`,
  [k.CLICK_HERE_TO_TRY_AGAIN]: `Click here to try again`,
  [k.EMAIL1]: `Email`,
  [k.CREATING]: `Creating`,
  [k.ORG1]: `Org...`,
  [k.YOUR_ORG_IS_READY_YOU_WILL_BE]: `Your org is ready. You will be redirected shortly.`,
  [k.ERROR_CREATING_YOUR_ORG]: `Error creating your org`,
  [k.SOMEONE_FROM_UNIFIZE_WILL_SOON]: `Someone from Unifize will soon contact you.`,
  [k.ORG_NAME]: `Org Name`,
  [k.EMAIL_ADDRESS]: `Email address`,
  [k.AT_LEAST_CHARACTERS]: `At least 8 characters`,
  [k.A_MIXTURE_OF_LETTERS_AND_NUMBE]: `A mixture of letters and numbers.`,
  [k.INCLUSION_OF_AT_LEAST_ONE_UPPE]: `Inclusion of at least one uppercase alphabet`,
  [k.CONFIRM_PASSWORD]: `Confirm Password`,
  [k.FIRST_NAME]: `First Name`,
  [k.LAST_NAME]: `Last Name`,
  [k.PLEASE_VERIFY_YOUR_EMAIL_ADDRE]: `Please verify your email address...`,
  [k.CLICK_ON_THE_LINK_WE_SENT_TO]: `Click on the link we sent to`,
  [k.TO_CREATE_YOUR_ORG]: `to create your org.`,
  [k.DIDN_T_RECEIVE_AN_EMAIL]: `Didn't receive an email?`,
  [k.RESEND_EMAIL]: `Resend email`,
  [k.STEP_OF]: `Step 1 of 3`,
  [k.SIGN_UP]: `Sign up`,
  [k.SPEED_UP_YOUR_PROCESSES_BY_X]: `Speed up your processes by 3x in 30 days`,
  [k.STEP_OF1]: `Step 2 of 3`,
  [k.CREATE_ACCOUNT]: `Create account`,
  [k.SIGN_IN]: `Sign in`,
  [k.YOU_HAVE_ALREADY_USED_THIS_EMA]: `You have already used this email to create an accuont.`,
  [k.STEP_OF2]: `Step 3 of 3`,
  [k.SIGN_OUT]: `Sign Out`,
  [k.LAST_STEP]: `Last Step`,
  [k.INVITE_YOUR_COLLABORATORS]: `, Invite Your Collaborators`,
  [k.NO_MORE_SILOS_INVITE_UP_TO]: `No more silos. Invite up to 4 colleagues now. You can invite more
            later.`,
  [k.STAGE_INFO_HEADER]: `Stage Info header:`,
  [k.STAGE]: `Stage:`,
  [k.THIS_LINK_IS_NOT_VALID]: `This link is not valid`,
  [k.NEXT]: `Next`,
  [k.BY_SIGNING_UP_I_AGREE_TO_THE]: `By signing up, I agree to the Unifize Privacy Policy and Terms of
              Service.`,
  [k.CENTER_SECTION]: `Center Section`,
  [k.RIGHT_SECTION]: `Right Section`,
  [k.ADD_SECTION]: `+ Add Section`,
  [k.SELECT_FROM_EXISTING_TILES]: `Select from existing tiles`,
  [k.CREATE_NEW_TILE]: `Create new tile`,
  [k.CONVERSATIONS]: `Conversations`,
  [k.MY_CONVERSATION_CHART]: `My Conversation Chart`,
  [k.START_NEW]: `Start New`,
  [k.PIN_A_DASHBOARD_CHART]: `Pin a dashboard chart`,
  [k.NAME2]: `NAME`,
  [k.DO_YOU_WANT_TO_DELETE1]: `Do you want to delete`,
  [k.ADD_NEW_TILE]: `+ Add new tile`,
  [k.SUB_TITLE]: `Sub-title`,
  [k.FILTERS]: `Filters`,
  [k.SELECT_AT_LEAST_ONE]: `(Select at least one)`,
  [k.UNREAD]: `Unread`,
  [k.I_OWN]: `I Own`,
  [k.BLUECROSS]: `blueCross`,
  [k.EVERYONE]: `Everyone`,
  [k.EVERYONE1]: `everyOne`,
  [k.BUTTON_TEXT]: `Button text`,
  [k.WHEN_EMPTY]: `When empty`,
  [k.TILE_COLOR]: `Tile color`,
  [k.SELECT_A_CHART]: `Select a chart`,
  [k.DON_T_SHOW_TILE]: `Don't show tile`,
  [k.SHOW_A_MESSAGE]: `Show a message`,
  [k.EXCLUDE_PROCESS]: `Exclude process`,
  [k.INCLUDE_PROCESS]: `Include Process`,
  [k.COLUMNS]: `Columns`,
  [k.OVERDUE]: `Overdue`,
  [k.TILE_TYPE_CONVERSATIONS]: `Tile Type: Conversations`,
  [k.TILE_TYPE_MY_CONVERSATION_CHA]: `Tile Type: My Conversation Chart`,
  [k.TILE_TYPE_START_NEW]: `Tile Type: Start New`,
  [k.TILE_TYPE_DASHBOARD_CHART]: `Tile Type: Dashboard Chart`,
  [k.SECTION1]: `Section:`,
  [k.ADD_USER_SEGMENT]: `+ Add user segment`,
  [k.AND_USER]: `And user`,
  [k.SHOW_TO]: `Show to`,
  [k.AND_USER1]: `+ And user`,
  [k.AGE1]: `age`,
  [k.SELECT_TIMEPERIOD]: `Select Timeperiod`,
  [k.SELECTED]: `selected`,
  [k.SELECT_GROUP]: `Select Group`,
  [k.SELECT_VALUES]: `Select Values`,
  [k.CREATE_NEW_USER_SEGMENT]: `Create new user segment`,
  [k.OTHERS1]: `others`,
  [k.ENABLE_HOME_SCREEN]: `Enable Home Screen`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DISAB]: `Are you sure you want to disable`,
  [k.INTEGRATION]: `integration?`,
  [k.DISABLE]: `Disable`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DISCO]: `Are you sure you want to disconnect the`,
  [k.INTEGRATION_YOU_COULD]: `integration? You could
          lose data that is accessed or hosted through`,
  [k.YOU_CANNOT_UNDO_THIS_ACTION_C]: `You cannot undo this action. Consider disabling the integration
          instead.`,
  [k.DISCONNECT]: `Disconnect`,
  [k.HEADING]: `heading`,
  [k.INTEGRATION1]: `Integration`,
  [k.INTEGRATE]: `Integrate`,
  [k.GLOBAL_OR_PRIVILEGED_ADMINIST]: `*Global or Privileged Administrators only`,
  [k.NO_APPS_FOUND]: `No apps found`,
  [k.ORG_SETTINGS]: `Org Settings`,
  [k.HOME_SCREEN1]: `Home Screen`,
  [k.ORG_DETAILS1]: `Org Details`,
  [k.ROLE_MANAGEMENT1]: `Role Management`,
  [k.APPS]: `Apps`,
  [k.DELETE_ROLE]: `Delete Role`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET3]: `Are you sure you want to delete this role?`,
  [k.DELETE_ORG_DOMAIN]: `Delete Org Domain`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DELET4]: `Are you sure you want to delete this org domain?`,
  [k.ORG_DOMAIN]: `Org Domain`,
  [k.EMAIL_PREFIX]: `Email Prefix`,
  [k.ALLOW_CLONING]: `Allow Cloning`,
  [k.ORG_DESCRIPTION]: `Org Description`,
  [k.ORG_DESCRIBER_VIDEO_PASTE_IFR]: `Org Describer Video (Paste iframe code)`,
  [k.ADD_USER]: `Add User`,
  [k.MEMBERS1]: `MEMBERS`,
  [k.ADD_USERS]: `+ Add Users`,
  [k.ADD_A_NEW_ROLE]: `+ Add a new Role`,
  [k.FUNCTIONALITY]: `Functionality`,
  [k.SERVICE_PROVIDER_ENTITY_ID]: `Service Provider Entity ID`,
  [k.AUTHORIZATION_CALLBACK_URL]: `Authorization Callback URL`,
  [k.HUGE_PARAGRAPH_HERE]: `(Huge paragraph here)`,
  [k.YES_LOGOUT_ALL_USERS]: `Yes, logout all users`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_DISAB1]: `Are you sure you want to disable SSO?`,
  [k.ENTER_NEW_PASSWORD]: `Enter new password`,
  [k.CONFIRM_NEW_PASSWORD]: `Confirm new password`,
  [k.DISABLE_SSO]: `Disable SSO`,
  [k.DOMAIN1]: `Domain`,
  [k._31]: `*`,
  [k.ENTITY_ID]: `Entity ID`,
  [k.SSO_URL]: `SSO URL`,
  [k.CERTIFICATE]: `Certificate`,
  [k.ADD_CERTIFICATE]: `Add certificate`,
  [k.MUST_START_WITH_BEGIN_CE]: `Must start with '-----BEGIN CERTIFICATE-----' and end with '-----END CERTIFICATE-----'`,
  [k.PAGE_NOT_FOUND]: `404 Page not found`,
  [k.WELCOME_BACK1]: `Welcome back`,
  [k._32]: `!`,
  [k.LOG_IN]: `Log in`,
  [k.TO_USE_THE_FULL]: `to use the full
                  version of the app`,
  [k.LOG_IN1]: `Log In`,
  [k.SIGN_UP1]: `Sign Up`,
  [k.DRIVE_PROCESS_THROUGH_CONVERSA]: `Drive process through conversation`,
  [k.UNIFIZE_INCREASES_YOUR_TEAM]: `Unifize increases your team`,
  [k.S_EFFICIENCY_BY_UP_TO_X_BY]: `s efficiency by up to 10x by
            connecting its conversations to process objectives.`,
  [k.SIGN_UP_FAILED]: `Sign Up failed!`,
  [k.CLICK_HERE_TO]: `Click here to`,
  [k.RESEND1]: `resend`,
  [k.VERIFICATION_EMAIL_SENT]: `Verification email sent`,
  [k.AN_EMAIL_HAS_BEEN_SENT_TO]: `An email has been sent to`,
  [k.PLEASE_OPEN_YOUR_EMA]: `. Please open
        your email and click on the link to get you setup on Unifize.`,
  [k.OR]: `Or`,
  [k.CHATROOM_HEADER]: `Chatroom Header`,
  [k.CLICK_HERE_TO_SIGN_UP_OR_LOG_I]: `Click here to sign up or log in.`,
  [k.HEADER]: `header`,
  [k.INPUT]: `input`,
  [k.YOU_NEED_TO_LOG_IN_TO_DO_THAT]: `You need to log in to do that`,
  [k.HISTORY]: `history`,
  [k.PANEL]: `panel`,
  [k.PLUSCIRCLE]: `plusCircle`,
  [k.FILE]: `file`,
  [k.MANAGE_FILE_VIEW]: `manage-file-view`,
  [k.CREATEPROCESSTEMPLATE]: `createProcessTemplate`,
  [k.REMOVECIRCLE]: `removeCircle`,
  [k.CLEAR_ALL1]: `Clear all`,
  [k.OPTION_NOT_FOUND]: `Option not found`,
  [k.ADD_NEW_OPTION]: `Add New Option`,
  [k.NEW_OPTION]: `New Option`,
  [k.EMAIL_NOTIFICATIONS]: `Email Notifications`,
  [k.SELECT_YOUR_PREFERED_NOTIFICAT]: `Select your prefered notification settings`,
  [k.NOTIFICATIONS]: `Notifications`,
  [k.EVERYTHING]: `Everything:`,
  [k.ALL_UPDATES_AND_MESSAGES]: `All updates and messages`,
  [k.FOR_CONVERSATIONS_YOU_ARE_A_PA]: `For conversations you are a part of`,
  [k.YOU_WILL_BE_NOTI]: `, you will be
            notified via email for all these updates:`,
  [k.ALL_COMMENTS]: `All comments`,
  [k.AND_MESSAGES]: `and messages`,
  [k.YOU_VE_BEEN_ADDED]: `you've been added`,
  [k.TO_A_CONVERSATION]: `to a conversation`,
  [k.ALL_STATUS_UPDATES]: `All status updates,`,
  [k.INCLUDING_WHEN_CONVERSATIONS]: `including when conversations
              are completed`,
  [k.WHEN_THE_CONVERSATION_IS]: `When the conversation is`,
  [k.OVERDUE1]: `overdue`,
  [k.AND_OTHER]: `and other`,
  [k.ANY_OWNER_CHANGE]: `Any owner change`,
  [k.OR_ASSIGNMENT]: `or assignment`,
  [k.WHEN_YOU_VE_BEEN]: `When you've been`,
  [k.MENTIONED]: `@mentioned`,
  [k.IN_A_CONVERSATION]: `in a conversation`,
  [k.IMPORTANT]: `Important:`,
  [k.STATUS_UPDATES_REMINDERS_AND]: `Status updates, reminders and @mentions`,
  [k.FOR_CONVERSATIONS_YOU_ARE_A_PA1]: `For conversations you are a part of,`,
  [k.YOU_WILL_BE_NOTIFI]: `you will be
            notified via email for all these updates:`,
  [k.ALL_STATUS_UPDATES1]: `All status updates`,
  [k.INCLUDING_WHEN_CONVERSATIONS1]: `, including when conversations
              are completed`,
  [k.AND_OTHER_REMIND]: `and other
              reminders`,
  [k.CRITICAL1]: `Critical:`,
  [k.CRITICAL_UPDATES_AND_MENTIONS]: `Critical updates and @mentions`,
  [k.YOU_VE_BEEN_ASSIGNED_AS_THE_OW]: `You've been assigned as the owner`,
  [k.OF_A_CONVERSATIO]: `of a
              conversation`,
  [k.YOU_VE_BEEN]: `You've been`,
  [k.NOTHING_NOT_RECOMMENDED]: `Nothing (Not Recommended)`,
  [k.YOU_WILL_NOT_BE_NOTIFIED_VIA_E]: `You will not be notified via email. This may result in you missing
            out on very important conversations that you're part of.`,
  [k.SORT]: `Sort`,
  [k.FILTER_VALUES1]: `Filter values`,
  [k.SEARCH1]: `search`,
  [k.BLUETICK]: `blueTick`,
  [k.CREATE_NEW_STATUS]: `+ Create new status`,
  [k.ENTER_STATUS_TITLE]: `Enter status title`,
  [k.ACTIVERADIOBUTTON]: `activeRadioButton`,
  [k.DEFAULTRADIOBUTTON]: `defaultRadioButton`,
  [k.MAP_TO_PENDING]: `Map to Pending`,
  [k.MAP_TO_COMPLETE]: `Map to Complete`,
  [k.DISABLE_UNTIL_APPROVED]: `Disable until approved`,
  [k.DELETETRASH]: `deleteTrash`,
  [k.CIRCLEDOWN]: `circleDown`,
  [k.HIDE_UNTIL_CONDITION_MET]: `Hide until condition met`,
  [k.ENABLE_UNTIL_CONDITION_MET]: `Enable until condition met`,
  [k.STATUS_TYPE]: `Status Type`,
  [k.ARCHIVE_BEHAVIOR]: `Archive behavior`,
  [k.IGNORE_ARCHIVING]: `Ignore archiving`,
  [k.AUTO_UNARCHIVE]: `Auto unarchive`,
  [k.STATUS_SETTINGS]: `Status Settings`,
  [k.YOU_NEED_TO_HAVE_AT_LEAST_ONE]: `You need to have at least one status mapped to`,
  [k.PENDING2]: `PENDING`,
  [k.AND]: `and`,
  [k.COMPLETED1]: `COMPLETED`,
  [k.EACH]: `each.`,
  [k.OK]: `OK`,
  [k._34]: `✕`,
  [k.UPDATE_HAS_BEEN_RELASED]: `Update has been relased`,
  [k.THERE_IS_A_NEW_VERSION_OF_THE]: `There is a new version of the app available, click refresh to view
            it.`,
  [k.ACCESS_DENIED]: `Access Denied!!`,
  [k.CONTACT_US]: `Contact us`,
  [k.LOOKS_LIKE_YOUR_EMAIL_IS_NOT_V]: `Looks like your email is not verified`,
  [k.AN_EMAIL_HAS_BEEN_SENT_TO_YOUR]: `An email has been sent to your account. Please open your email and click
      on the link to get you setup on Unifize.`,
  [k.STILL_HAVING_TROUBLES]: `Still having troubles?`,
  [k.FORGOT_YOUR_PASSWORD]: `Forgot your password?`,
  [k.ENTER_THE_EMAIL_ADDRESS_YOU_US]: `Enter the email address you used for this account. You will receive
            an email within few minutes with a link to reset your password.`,
  [k.SEND_LINK]: `Send Link`,
  [k.PASSWORD1]: `password`,
  [k.LOADING1]: `loading`,
  [k.LOADING_CONVERSATIONS_FOR]: `Loading conversations for`,
  [k.UNABLE_TO_LOGIN]: `Unable to login -`,
  [k.CONTINUE_IN_APP]: `Continue in App`,
  [k.HEY_THERE_LOOKS_LIKE_YOU_RE_T]: `Hey there! Looks like you're trying to login from a mobile device.`,
  [k.CONTINUE_WITH_UNIFIZE_MOBILE_A]: `Continue with Unifize Mobile App`,
  [k.ABOUT_US]: `About us`,
  [k.RESET_YOUR_PASSWORD]: `Reset Your Password`,
  [k.YOU_HAVE_CLICKED_ON_A_PASSWORD]: `You have clicked on a password reset link for:`,
  [k.ENTER_A_NEW_PASSWORD]: `Enter a new password`,
  [k.RESET_MY_PASSWORD]: `Reset My Password`,
  [k.PASSWORD_RESET]: `Password reset`,
  [k.RESET_LINK_SENT]: `Reset link sent`,
  [k.RESET_INSTRUCTIONS_HAVE_BEEN_S]: `Reset instructions have been sent to`,
  [k.YOU_WILL_RECEIVE]: `. You
            will receive the email within 5 minutes. The email could be hidden
            in your spam folder.`,
  [k.RESENT_INSTRUCTIONS]: `Resent Instructions`,
  [k.PASSWORD_CHANGE_SUCCESSFUL]: `Password Change Successful`,
  [k.YOUR_PASSWORD_HAS_BEEN_RESET_S]: `Your password has been reset successfully for:`,
  [k.CONTINUE_TO_YOUR_UNIFIZE_APP]: `Continue to Your Unifize App`,
  [k.SERVER_ERROR]: `Server Error`,
  [k.RELOAD]: `Reload`,
  [k.LOGOUT]: `Logout`,
  [k.XL]: `2xl`,
  [k.FORGOT_PASSWORD]: `Forgot password?`,
  [k.DON]: `Don`,
  [k.T_HAVE_AN_ACCOUNT]: `t have an account?`,
  [k.SIGN_UP_NOW]: `Sign up Now!`,
  [k.THIS_EMAIL_ADDRESS_IS_NOT_VERI]: `This email address is not verified`,
  [k.THE_USERNAME_OR_PASSWORD_IS_IN]: `The username or password is incorrect`,
  [k.TOO_MANY_FAILED_LOGIN_ATTEMPTS]: `Too many failed login attempts, try again later`,
  [k.SOMETHING_WENT_WRONG_TRY_AGAI]: `Something went wrong, try again later`,
  [k.PLEASE_CHECK_YOUR_INBOX]: `Please check your inbox`,
  [k.VERIFYING_YOUR_LINK]: `Verifying your link`,
  [k.NO_CHARTS_FOUND]: `No charts found`,
  [k.REFRESH1]: `refresh`,
  [k.SELECT_FIELD1]: `Select field`,
  [k.TASKLIST]: `tasklist`,
  [k.MANDATORY1]: `Mandatory`,
  [k.MANDATORY_FIELD_REMAINING]: `Mandatory field remaining`,
  [k.OPEN_CHECKLIST]: `Open Checklist`,
  [k.CARETLEFTFILLED]: `caretLeftFilled`,
  [k.CLICK_TO_ADD_DESCRIPTION]: `Click to Add Description`,
  [k.VIEW_IN_ACTIVE_CHAT]: `View in Active Chat`,
  [k.UNABLE_TO_PERFORM_ACTION]: `Unable to perform action`,
  [k.YOU_SHOULD_NAVIGATE_TO_CONVERS]: `You should Navigate to conversation to perform this action.`,
  [k.VIEW_IN_ACTIVE_CHAT1]: `View in active chat`,
  [k.TO_PERFORM_THIS_ACTION]: `to perform this action`,
  [k.CONVERSATION_DIALOG]: `conversation-dialog`,
  [k.CONVERSATION_DIALOG1]: `#conversation-dialog`,
  [k.DATEACTIVE]: `dateActive2`,
  [k.DATEACTIVE1]: `dateActive`,
  [k.FILES_FROM_UNIFIZE_CONVERSATIO]: `Files from Unifize conversations`,
  [k.ATTACH_FILES_FROM_YOUR_COMPUTE]: `Attach files from your computer`,
  [k.CC]: `Cc`,
  [k.BCC]: `Bcc`,
  [k.SUBJECT]: `Subject`,
  [k.SEND_AS_EMAIL]: `Send as email`,
  [k.DISCARD]: `Discard`,
  [k.SEND]: `Send`,
  [k.FILES_IN_CHATROOM]: `Files in chatroom`,
  [k.ACCESS_REQUEST]: `Access Request`,
  [k.PEOPLE_HAVE_REQUESTED_TO_ACCES]: `people have requested to access this private
          conversastion`,
  [k.ACCEPT]: `Accept`,
  [k.PEOPLE]: `people`,
  [k.HAVE_REQUESTED_TO]: `have requested to
            join the conversation`,
  [k.RESPOND]: `Respond`,
  [k.HAS_REQUESTED_TO_JOIN]: `has requested to join
        the conversation`,
  [k.FAVOURITE]: `favourite`,
  [k._35]: `;`,
  [k.CHANGE_PROCESS_TYPE]: `Change Process Type`,
  [k.TYPE2]: `type-`,
  [k.DAYS_OLD]: `days old`,
  [k.LESS_THAN_A_DAY_OLD]: `Less than a day old`,
  [k.LINK_COPIED]: `Link copied`,
  [k.CONVERSATION_LINK_HAS_BEEN_COP]: `Conversation link has been copied`,
  [k.CONVERSATION_LINK]: `Conversation Link`,
  [k.DOWNLOAD_JSON]: `Download JSON`,
  [k.FILES1]: `Files (`,
  [k.NO_RESULTS_FOR]: `No results for`,
  [k.EMAIL_ADDRESS_COPIED]: `Email address copied`,
  [k.USE_COPIED_EMAIL_ADDRESS_TO_FO]: `Use copied email address to forward your mail to this conversation`,
  [k.FORWARD_MAIL_HERE]: `Forward mail here`,
  [k.PRIVACY]: `Privacy`,
  [k.HIDE_EVERYTHING1]: `(Hide everything)`,
  [k.HIDE_ONLY_CHAT_CONTENT1]: `(Hide only chat content)`,
  [k.PROCESS_BUILDER]: `Process Builder`,
  [k.RELATED_CONVERSATIONS]: `Related Conversations (`,
  [k.MOREMENUFILLED]: `moreMenuFilled`,
  [k.MOREMENU]: `moreMenu`,
  [k.MORE2]: `More`,
  [k.V]: `V`,
  [k.REVISIONS1]: `Revisions`,
  [k.CHAT_INPUT]: `chat-input`,
  [k.PLUS]: `plus`,
  [k.PLUSCHATICON]: `plusChatIcon`,
  [k.D]: `d`,
  [k.FORWARD_TO]: `Forward to`,
  [k.FORWARD]: `Forward`,
  [k.UPLOAD_IMAGE]: `Upload image`,
  [k.ARE_YOU_SURE_YOU_WANT_TO_UPLOA]: `Are you sure you want to upload this image?`,
  [k.OWNER2]: `OWNER`,
  [k.SELECTED1]: `Selected`,
  [k.RECENT]: `recent`,
  [k.ADD_LINK]: `Add Link`,
  [k.ENTER_VALID_URL]: `Enter valid URL`,
  [k.MENTIONBUTTON]: `mentionButton`,
  [k.NO_RESULTS_FOUND]: `No results found`,
  [k.EVERY_PARTICIPANT_OF_THE_CONV]: `(every participant of the conversation)`,
  [k.CURRENT_OWNER_OF_THE_CONVERSA]: `(current owner of the conversation)`,
  [k.CREATOR_OF_THE_CONVERSATION]: `(creator of the conversation)`,
  [k.LOADING_MESSAGES]: `Loading messages...`,
  [k.JOIN]: `Join`,
  [k.FULL_NAME_OPTIONAL]: `Full Name (optional)`,
  [k.EMAIL_ADDRESS1]: `Email Address`,
  [k.SELECT_ROLE]: `Select role`,
  [k.SEND_INVITE]: `Send Invite`,
  [k.ADD_PARTICIPANTS_OR_GROUPS]: `Add participants or groups`,
  [k.NAME_EMAIL_OR_GROUP]: `NAME, EMAIL OR GROUP`,
  [k.INVITE]: `Invite "`,
  [k.NO_RESULTS_FOR1]: `No results for "`,
  [k.TO_INVITE_SOMEONE_EXTERNALLY]: `To invite someone externally, please use their email address. eg.`,
  [k.JOHN_DOE_ACME_COM]: `john.doe@acme.com`,
  [k.TO_CONVERSATION]: `to conversation`,
  [k.SEND_INVITES]: `Send Invites`,
  [k.PARTICIPANTS1]: `PARTICIPANTS`,
  [k.ADD_PARTICIPANTS_OR_GROUPS1]: `+ Add participants or groups`,
  [k.PARTICIPANT]: `Participant`,
  [k.CURVE]: `curve`,
  [k.DELETED_USER]: `Deleted User`,
  [k.CHAT_WITH]: `Chat with`,
  [k.START_RELATED_CONVERSATION]: `Start Related Conversation`,
  [k.OTHER]: `Other`,
  [k.ADD_TO_CONVERSATION]: `Add to Conversation`,
  [k.MEMBER1]: `Member`,
  [k.UPLOAD_FILES]: `Upload files`,
  [k.FROM_PRIVATE_CONVERSATION]: `from private conversation`,
  [k.START_PROCESS]: `Start Process`,
  [k.REMOVE_OWNER]: `Remove Owner`,
  [k.INVITE1]: `Invite`,
  [k.INVITE_VIA_EMAIL]: `Invite via email`,
  [k.ASSIGN_OWNER]: `Assign owner`,
  [k.NOOWNERACTIVE]: `noOwnerActive`,
  [k.NOOWNER]: `noOwner`,
  [k.ASSIGN_OWNER1]: `Assign Owner`,
  [k.NO_OWNER]: `No Owner`,
  [k.MESSAGE]: `message`,
  [k.SET_PRIORITY]: `Set Priority`,
  [k.SET_DUE_DATE]: `Set due date`,
  [k.SET_PRIORITY_FOR_CONVERSATION]: `Set priority for this conversation`,
  [k.BOLD]: `bold`,
  [k.GRAY2]: `gray.200`,
  [k.PRIORITY2]: `Priority:`,
  [k.FAILED_TO_UPLOAD]: `Failed to upload`,
  [k.UPLOADING]: `Uploading`,
  [k.RESTRICTED_ACCESS]: `Restricted access`,
  [k.THE_CONVERSATION_YOU_ARE_TRYIN]: `The conversation you are trying to access is not open to your
            account. Contact your org admin to get access.`,
  [k.FAILED_TO_LOAD_THE_CHATROOM]: `Failed to load the chatroom`,
  [k.CONVERSATION_NOT_FOUND]: `Conversation not found`,
  [k.DEFINE_NEW]: `Define New`,
  [k.CHATROOMSEARCH]: `chatroomSearch`,
  [k.X3]: `X`,
  [k.ADDITIONAL_COMMENTS]: `Additional comments`,
  [k.SKIP]: `Skip`,
  [k.ARCHIVE]: `Archive`,
  [k.THIS_CONVERSATION]: `this conversation`,
  [k.ARCHIVE1]: `archive`,
  [k.CROP_UPLOAD]: `Crop & Upload`,
  [k.FILEINPUT]: `fileInput`,
  [k.BOOLEAN]: `Boolean`,
  [k.DETAILS_OBJECTIVE]: `Details / Objective`,
  [k.DUE_DATE]: `Due Date`,
  [k.START_DIRECT_CONVERSATION]: `Start Direct Conversation`,
  [k.YOU_CANT_CHAT_WITH_YOURSELF]: `You cant chat with yourself`,
  [k.CHOOSE_USER_TO_CHAT_WITH]: `Choose user to chat with`,
  [k.TO_INVITE_SOMEONE_EXTERNALLY1]: `To invite someone externally, please use their email address.
          eg.john.doe@acme.com`,
  [k.PARENTS]: `Parents`,
  [k.CHILDREN]: `Children`,
  [k.OVERDUE2]: `Overdue (`,
  [k.COMPLETED2]: `Completed (`,
  [k.I]: `I`,
  [k.M_OWNER]: `m Owner (`,
  [k.RELATED_CONVERSATIONS1]: `Related Conversations`,
  [k.RELATED_CHILDREN]: `Related Children (`,
  [k.RELATED_PARENTS]: `Related Parents (`,
  [k.SHOWING]: `Showing`,
  [k.OPTION]: `option`,
  [k.I_M_THE_OWNER]: `I'm the Owner`,
  [k.RELATED]: `related`,
  [k.DATEWARNING]: `dateWarning`,
  [k.COMPLETED3]: `completed`,
  [k.INCOMPLETE1]: `incomplete`,
  [k.RELATED1]: `Related`,
  [k.NO_LONGER_MATCHES_THE_LIST_F]: `No longer matches the list / filter`,
  [k.SEND_A_DIRECT_MESSAGE]: `Send a direct message`,
  [k.START_WITH_THIS_SOLUTION]: `Start with this solution`,
  [k.NO_MATCHING_RESULTS1]: `No matching results`,
  [k.THERE_WEREN_T_ANY_CONVERSATION]: `There weren't any conversations matching your filters.`,
  [k.TRY_WIDENING_YOUR_SEARCH]: `Try widening your search.`,
  [k.CONGRATULATIONS]: `Congratulations!`,
  [k.YOU_HAVE_UNREAD_CONVERSATION]: `You have 0 unread conversations here.`,
  [k.TRY_APPLYING_OTHER_FILTERS]: `Try applying other filters.`,
  [k.EDIT_FILTER]: `Edit Filter`,
  [k.SAVE_FILTER]: `Save Filter`,
  [k.ERROR_EDITING_FILTER]: `Error editing filter`,
  [k.FILTER_CANNOT_BE_SAVED]: `Filter Cannot be saved`,
  [k.SORT_BY3]: `SORT BY`,
  [k.CUSTOM]: `CUSTOM`,
  [k.VIEW_AND_SELECT_FROM_ALL_STATU]: `View and select from all statuses`,
  [k.STATUS2]: `STATUS`,
  [k.CLOSEFILTER]: `closeFilter`,
  [k.CLEAR]: `Clear`,
  [k.ALL_TYPES]: `ALL TYPES`,
  [k.SAVED_FILTERS]: `SAVED FILTERS`,
  [k.QUICK_FILTERED_LISTS]: `QUICK FILTERED LISTS`,
  [k.SUGGESTED]: `SUGGESTED`,
  [k.ALL_CONVERSATIONS]: `all conversations`,
  [k.ALL_CONVERSATIONS1]: `All Conversations`,
  [k.FAVOURITES]: `favourites`,
  [k.FAVOURITES1]: `Favourites`,
  [k.STAR]: `star`,
  [k.ACTIVEQUICKFILTER]: `activeQuickFilter`,
  [k.DEFAULTQUICKFILTER]: `defaultQuickFilter`,
  [k.BACKARROW]: `backArrow`,
  [k.CHANGE]: `Change`,
  [k.CONVERSATION_TYPE_NOT_FOUND]: `Conversation type not found`,
  [k.ALL_PROCESSES]: `All Processes`,
  [k.ALL_TASKS]: `All Tasks`,
  [k.ALL_APPROVALS]: `All Approvals`,
  [k.SEARCH_CONVERSATION]: `Search Conversation`,
  [k.ADD_A_DEPARTMENT]: `Add a Department`,
  [k.SEARCH_FOR_DEPARTMENTS]: `Search for Departments`,
  [k.NO_DEPARTMENT]: `No Department`,
  [k.CREATE_NEW_DEPARTMENT]: `Create new department`,
  [k.ENTER_YOUR_DEPARTMENT]: `Enter your department`,
  [k.OBJECT1]: `Object`,
  [k.CLONE1]: `Clone`,
  [k.ORGANISE]: `Organise`,
  [k.CREATE_NEW_FOLDER]: `Create New Folder`,
  [k.NEW_FOLDER]: `New Folder`,
  [k.MEDIUM]: `medium`,
  [k.MOVE_HERE]: `Move Here`,
  [k.ALL_ORG_FILES]: `All Org Files`,
  [k.MY_DRIVE]: `My Drive`,
  [k.V1]: `v`,
  [k.CURRENT_VERSION]: `current version`,
  [k.MAKE_CURRENT1]: `make current`,
  [k.VERSION_HISTORY]: `Version History`,
  [k.PIN]: `Pin`,
  [k.TO_ACTIVE_CONVERSATION]: `to active conversation`,
  [k.PIN1]: `pin`,
  [k.PIN_TO_ACTIVE_CONVERSATION]: `Pin to active conversation`,
  [k.ORGANISE1]: `organise`,
  [k.TRASH]: `trash`,
  [k.SEE_RELATED_CONVERSATIONS]: `See related conversations`,
  [k.FILE_NAME]: `File name`,
  [k.SIZE]: `Size`,
  [k.LAST_MODIFIED]: `Last modified`,
  [k.UPLOADED_BY]: `Uploaded by`,
  [k.MY_FILES]: `My Files`,
  [k.ALL_FILES]: `All Files`,
  [k.PIN_CONVERSATION]: `Pin conversation`,
  [k.PIN_FILE]: `Pin File "`,
  [k.TO1]: `" to "`,
  [k.ADD_MORE]: `Add more`,
  [k.OPTIONAL_COMMENT]: `Optional comment:`,
  [k.FILES_NOT_FOUND]: `Files not found`,
  [k.NO_RESULT]: `No result`,
  [k.UPLOAD_FILE]: `Upload File`,
  [k.SORT_BY_ALPHABET]: `Sort by Alphabet`,
  [k.LAST_MODIFY]: `Last Modify`,
  [k.RECENTLY_VIEWED]: `Recently Viewed`,
  [k.UPLOAD_A_FILE]: `Upload a file`,
  [k.UPLOAD_FILE1]: `Upload file`,
  [k.UPLOAD_NEW_VERSION1]: `Upload new version`,
  [k.THIS_FILE_IS_SHARED_ON_A_FOLDE]: `This file is shared on a folder and with other threads.
                Uploading a new version will change all these files. (Older
                version history will be maintained for last 5 versions)`,
  [k.IT_LOOKS_LIKE_YOU_ARE_UPLOADIN]: `It looks like you are uploading a new version of file "`,
  [k.IS_THAT_CORRECT]: `". Is that correct?`,
  [k.ENTER_VERSION_NOTES_COMMENTS]: `Enter version notes/comments (optional)`,
  [k.CONTINUE]: `Continue`,
  [k.NO_ORGS_WITH_GIVEN_NAME]: `No Orgs with given name`,
  [k.DASHBOARD1]: `dashboard`,
  [k.MANAGE1]: `manage`,
  [k.CRASH]: `Crash`,
  [k.DISPLAYNAME_ASC]: `displayName:asc`,
  [k.ACTIVE]: `Active`,
  [k.CLONE_ORG]: `Clone Org`,
  [k.COPY_LINK]: `Copy Link`,
  [k.CREATE_NEW_ORG]: `Create New Org`,
  [k.TEAM_NAME]: `Team Name`,
  [k.INVITE_YOUR_TEAM_MEMBERS]: `Invite your team members`,
  [k.TEAM_MEMBER_OPTIONAL]: `Team member (optional)`,
  [k.DOMAIN_NAME]: `Domain name`,
  [k.OPTIONAL1]: `(optional)`,
  [k.ALLOW_ANYONE_WITH]: `Allow anyone with`,
  [k.EMAIL_TO_JOIN_THIS_TEAM]: `email to join this team.`,
  [k.ALLOW_ANYONE_WITH_GIVEN_DOMAIN]: `Allow anyone with given domain join this team`,
  [k.ORGDARK]: `orgDark`,
  [k.ORG2]: `org`,
  [k.CHANGE_PASSWORD]: `Change Password`,
  [k.CIRCLECHECK]: `circleCheck`,
  [k.PASSWORD_DOES_NOT_MATCH]: `(Password does not match)`,
  [k.CHANGE_ORG]: `Change Org`,
  [k.UPDATE_NAME]: `Update Name`,
  [k.CHANGE_PROFILE_PICTURE]: `Change Profile Picture`,
  [k.CHANGE_PASSWORD1]: `Change password`,
  [k.ORG_SETTINGS1]: `Org settings`,
  [k.A_NEW_VERSION_OF_UNIFIZE_HAS_B]: `A new version of Unifize has been released.`,
  [k.UPDATE_NOW]: `UPDATE NOW`,
  [k.VIEW_AS]: `View As`,
  [k.SETTING_UP_YOUR_PROFILE]: `Setting up your profile`,
  [k.PLEASE_COMPLETE_YOUR_PROFILE_B]: `Please complete your profile below so you can start using Unifize`,
  [k.FULL_NAME]: `Full name`,
  [k.CREATE_PASSWORD]: `Create Password`,
  [k.PROFILE_PIC]: `profile pic`,
  [k.IMAGE1]: `image/*`,
  [k.ADD_PROFILE_PHOTO_OPTIONAL]: `Add Profile Photo (Optional)`,
  [k.SOMETHING_WENT_WRONG]: `Something went wrong :(`,
  [k.PLEASE_TRY_CLICKING_ON_THE_INV]: `Please try clicking on the invite link again!`,
  [k.SETTING_UP_UNIFIZE_FOR_YOU]: `Setting up Unifize for you`,
  [k.VERIFYING_CREDENTIALS]: `Verifying credentials`,
  [k.DELETED_A]: `deleted a`,
  [k.FORWARDED_FROM]: `Forwarded from`,
  [k.HID_A_MESSAGE]: `hid
        a message.`,
  [k.UNHIDE]: `Unhide`,
  [k.FIRST]: `first`,
  [k.UNARCHIVE1]: `unarchive`,
  [k.IMG_LOADING]: `img loading...`,
  [k.UPDATED]: `Updated`,
  [k.CANCELLED_THIS_CONVERSATION]: `cancelled this conversation`,
  [k.CANCELLED1]: `cancelled`,
  [k.COMPLETED_THIS_CONVERSATION]: `completed this conversation`,
  [k.THIS_IS_YOUR_DIRECT_MESSAGE_WI]: `This is your direct message with @`,
  [k.THIS_CONVERSATION_IS_PRIVATE]: `This conversation is private`,
  [k.REOPENED_THIS_CONVERSATION]: `reopened this conversation`,
  [k.REOPENED]: `reopened`,
  [k.PHOTO1]: `Photo`,
  [k.STARTED_THIS_CONVERSATION]: `started this conversation`,
  [k.STARTED]: `started`,
  [k.UPDATED_STATUS_OF_THIS_CONVERS]: `updated status of this conversation to`,
  [k.UPDATED_STATUS_OF_THE_CONVERSA]: `updated status of the conversation`,
  [k.UPDATED_THE_DUE_DATE_TO]: `updated the due date to`,
  [k.SET_THE_DUE_DATE_TO]: `set the due date to`,
  [k.REMOVED_THE_DUE_DATE]: `removed the due date`,
  [k.READ]: `read`,
  [k.DELIVERED]: `delivered`,
  [k.TO2]: `To:`,
  [k.CC1]: `Cc:`,
  [k.BCC1]: `Bcc:`,
  [k.NO_CONTENT]: `No content`,
  [k.ATTACHMENTS]: `attachments`,
  [k.ATTACHMENT]: `attachment`,
  [k.HIDE_DETAILS]: `Hide details`,
  [k.VIEW_FULL_EMAIL]: `View full email`,
  [k.LOADING2]: `Loading ...`,
  [k.DELETED_A_FILE]: `deleted a file.`,
  [k.MAIL]: `mail`,
  [k.UPLOADED_A_FILE]: `Uploaded a file`,
  [k.SENT_AN_EMAIL]: `Sent an email`,
  [k.ADDED_A_FORM]: `added a form`,
  [k.TO_THE_CHECKLIST]: `to the checklist`,
  [k.REMOVED_A_FORM]: `removed a form`,
  [k.FROM_THE_CHECKLIST1]: `from the checklist`,
  [k.ADDED_PARENT]: `added parent`,
  [k.REMOVED_PARENT]: `removed parent`,
  [k.ADDED_CHILD]: `added child`,
  [k.REMOVED_CHILD]: `removed child`,
  [k.UPDATED_THE_OBJECTIVE_TO]: `updated the objective to:`,
  [k.ADDED_OBJECTIVE]: `added objective:`,
  [k.REMOVED_OBJECTIVE]: `removed objective`,
  [k.CHANGED_THE_OWNER_FROM]: `changed the owner from`,
  [k.ASSIGNED]: `assigned`,
  [k.AS_THE_OWNER]: `as the owner`,
  [k.REMOVED]: `removed`,
  [k.ADDED_CHILD_CONVERSATION]: `added child conversation`,
  [k.REMOVED_CHILD_CONVERSATION]: `removed child conversation`,
  [k.UPDATED_THE_PRIORITY_FROM]: `updated the priority from`,
  [k.CHANGED_THE_PRIVACY_SETTING_TO]: `changed the privacy setting to`,
  [k.TURNED_OFF_REMINDERS_FOR_THIS]: `turned off reminders for this conversations.`,
  [k.TURN_ON]: `Turn On`,
  [k.TURNED_ON_REMINDERS_FOR_THIS_C]: `turned on reminders for this conversations.`,
  [k.TURN_OFF]: `Turn Off`,
  [k.UNIFIZE_ASSISTANT]: `Unifize Assistant`,
  [k.TURN_OFF_REMINDERS]: `Turn off reminders`,
  [k.REMINDERS_HAVE_BEEN_TURNED_OFF]: `Reminders have been turned off`,
  [k.OF_THIS_CONVERSATION]: `of this
        conversation`,
  [k.CREATED_A_REVISION]: `created a revision`,
  [k.MADE]: `made`,
  [k.AS_THE_CURRENT_REVISION1]: `as the current revision.`,
  [k.FROM]: `from #`,
  [k.FILES2]: `files`,
  [k.STARTED_THIS_CONVERSATION_FROM]: `started this conversation from`,
  [k.FORWARDED_FROM1]: `forwarded from`,
  [k.SENT_THIS_MESSAGE_FROM]: `sent this message from`,
  [k.THREAD]: `thread`,
  [k.DUE_ON]: `due on`,
  [k.UPDATED1]: `updated`,
  [k.TITLE_TO]: `title to`,
  [k.FROM1]: `from`,
  [k.ADDED]: `added`,
  [k.THE]: `the`,
  [k.INVITED]: `invited`,
  [k.TO_THE_CHATROOM]: `to the chatroom`,
  [k.JOINED_THE_CONVERSATION]: `joined the conversation`,
  [k.TODAY]: `Today`,
  [k.LOADMORE]: `loadMore`,
  [k.LOADING_MORE_CONVERSATIONS]: `Loading more conversations . . .`,
  [k.SIGNATORIES]: `Signatories`,
  [k._40]: `@`,
  [k.MESSAGEWITHMENTION]: `messageWithMention`,
  [k.MESSAGEWITHOUTMENTION]: `messageWithoutMention`,
  [k.REPLY_AS_EMAIL]: `Reply as Email`,
  [k.FORWARD_AS_EMAIL]: `Forward as Email`,
  [k.START1]: `Start`,
  [k.STEP_DISMISSED]: `Step Dismissed`,
  [k.UNDO]: `UNDO`,
  [k.HEY]: `Hey`,
  [k.CAN_YOU_COMPLETE_THIS_STEP]: `, can you complete this step?`,
  [k.DISMISS]: `Dismiss`,
  [k.CHECKLIST_FIELD_FILL]: `CHECKLIST_FIELD_FILL`,
  [k.YOU_CAN_ACCESS_THE_CONVERSATIO]: `You can access the conversation after a participant gives you access.`,
  [k.REQUEST_SENT]: `Request Sent`,
  [k.REQUEST_ACCESS]: `Request Access`,
  [k.THANK_YOU_FOR_YOUR_INTEREST]: `Thank you for your interest`,
  [k.OUR_SALES_TEAM_WILL_GET_IN_TOU]: `Our sales team will get in touch soon`,
  [k.VALIDATING_YOUR_EMAIL]: `Validating your email`,
  [k.SET_UP_NEW_ORG_ON_UNIFIZE]: `Set up new Org on Unifize`,
  [k.PLEASE_ENTER_YOUR_WORK_EMAIL_S]: `Please enter your work email so we can connect you to your
            organization's private space.`,
  [k.TRY_SIGNING_IN]: `Try signing in`,
  [k.BY_CLICKING_SIGN_UP_YOU_ACC]: `By clicking "Sign Up", you accept our`,
  [k.TERMS_OF_SERVICE]: `Terms of Service`,
  [k.AND_OUR]: `, and our`,
  [k.PRIVACY_POLICY]: `Privacy Policy`,
  [k.SENDING_VERIFICATION_MAIL]: `Sending verification mail`,
  [k.ERROR_SENDING_VERIFICATION_MAI]: `Error Sending Verification Mail`,
  [k.CLICK_HERE_TO_RESEND]: `Click here to resend.`,
  [k.PLEASE_VERIFY_YOUR_EMAIL]: `Please verify your email`,
  [k.PLEASE_OPEN_YOUR]: `.
            Please open your email and click on the link to get you setup on
            Unifize.`,
  [k.DIDN_T_GET_YOUR_EMAIL]: `Didn't get your email?`,
  [k.ERROR_VERIFYING_YOUR_LINK]: `Error Verifying Your Link`,
  [k.CHECK_YOUR_INBOX_FOR_THE_LATES]: `Check your inbox for the latest mail from us.`,
  [k.ENTER_EMAIL_ADDRESS_TO_SIGN_UP]: `Enter email address to sign-up`,
  [k.YOU_WILL_RECEIVE_A_VERIFICATIO]: `You will receive a verification email within a few minutes`,
  [k.DEPARTMENT]: `department`,
  [k.DEPARTMENT1]: `Department`,
  [k.PROCESS1]: `process`,
  [k.FILTERS1]: `FILTERS:`,
  [k.SHOW_TOTAL]: `Show Total`,
  [k.SHOW_AVERAGE]: `Show Average`,
  [k.LAST_UPDATED]: `Last updated:`,
  [k.LAST_DAYS]: `Last 7 days`,
  [k.LAST_MONTH]: `Last month`,
  [k.CHOOSE_DATE]: `Choose date`,
  [k.LAST_WEEK]: `Last week`,
  [k.ADD_DATE_RANGE_MANUALLY]: `Add date range manually`,
  [k.USER_NAME]: `User name`,
  [k.CONVERSATIONS_CREATED_DURING]: `Conversations created during`,
  [k.WHERE_USER_IS_A_PARTICIPANT]: `Where user is a participant`,
  [k.NOW_COMPLETED]: `Now completed`,
  [k.STILL_PENDING]: `Still pending`,
  [k.CURRENT_PENDING_CONVERSATIONS]: `Current pending conversations`,
  [k.UNREAD_BY_USER]: `Unread by user`,
  [k.OWNED_BY_USER]: `Owned by user`,
  [k.MARKED_AS_CRITICAL]: `Marked as critical`,
  [k.CONVERSATIONS_COMPLETED_DURING]: `Conversations completed during`,
  [k.CREATED_BY_USER]: `Created by user`,
  [k.COMPLETED_BY_USER]: `Completed by user`,
  [k.LAST_ACTIVE]: `Last active`,
  [k.AVERAGE]: `Average:`,
  [k.TOTAL]: `Total:`,
  [k.CONTACTS]: `Contacts`,
  [k.LOCK_GROUP]: `Lock Group`,
  [k.ONLY_THE_GROUP_OWNER_CAN_MAKE]: `Only the group owner can make changes to a locked group`,
  [k.EDIT_GROUP]: `Edit Group`,
  [k.CREATE_NEW_GROUP]: `Create New Group`,
  [k.GROUP_NAME]: `Group Name`,
  [k.ADD_PARTICIPANTS1]: `Add Participants`,
  [k.GROUP_OWNERS]: `Group Owners`,
  [k.CREATE_GROUP]: `Create Group`,
  [k.GROUP_LOGO]: `group logo`,
  [k.GROUP_NAME1]: `Group name`,
  [k.GROUP_OWNERS1]: `Group owners`,
  [k.ADDED_ON]: `Added on`,
  [k.RESET_PASSWORD]: `Reset Password`,
  [k.RESEND_INVITE]: `Resend Invite`,
  [k.MMM_DD_YYYY_HH_MM_A1]: `MMM DD, YYYY hh:mm A`,
  [k.DATE_COMPLETED]: `Date Completed`,
  [k.GROUPS1]: `Groups (`,
  [k.PROCESSES1]: `Processes (`,
  [k.ONBOARDING_PROGRESS]: `Onboarding Progress`,
  [k.PHONE_NUMBER]: `Phone Number`,
  [k.MESSAGE1]: `Message`,
  [k.ENABLE]: `Enable`,
  [k.ADMIN]: `admin`,
  [k.MAKE_MEMBER]: `Make member`,
  [k.MAKE_ADMIN]: `Make Admin`,
  [k.MMM_DD_YYYY_H_MM_A]: `MMM DD, YYYY h:mm a`,
  [k.MMM_DD_YYYY_H_MM_A1]: `MMM DD, YYYY h:mm A`,
  [k.COLUMN2]: `column-`,
  [k.ROLE]: `role`,
  [k.VIEW_PROFILE]: `View Profile`,
  [k.DIRECT_MESSAGE]: `Direct Message`,
  [k.PEOPLE1]: `People`,
  [k.ADMIN1]: `admin?`,
  [k.MEMBER2]: `member?`,
  [k.DISABLING_A_USER_S_ACCOUNT_WIL]: `Disabling a user's account will clear all their cached data.`,
  [k.MAKE]: `Make`,
  [k.S_ACCOUNT]: `'s account`,
  [k.USER_EXAMPLE_COM]: `user@example.com`,
  [k.ENTER_DISPLAY_NAME]: `Enter Display Name`,
  [k.IN]: `in`,
  [k.SEARCH_FOR_COUNTRY]: `Search for country`,
  [k.NO_PHONE_NUMBER]: `No Phone Number`,
  [k.ME2]: `me`,
  [k.CAN_T_FIND_ANYONE_WITH_THIS_NA]: `Can't find anyone with this name.`,
  [k.CAN_T_FIND_SOMEONE]: `Can't find someone?`,
  [k.TO_UNIFIZE_VIA_EMAIL]: `to Unifize via email`,
  [k.INVITE_THEM_TO_UNIFIZE_VIA_EMA]: `Invite them to Unifize via email`,
  [k.CIRCLE]: `circle`,
  [k.ME_ALWAYS_SELECTS_THE_VIEWER]: `Me (Always selects the viewer)`,
  [k.SEARCH_PEOPLE]: `Search People`,
  [k.E_G_JOHN_DOE_XYZ_COM_OR_JOHN]: `e.g. john.doe@xyz.com or John Doe`,
  [k.OUTLINE]: `outline`,
  [k.VIA_EMAIL]: `via email`,
  [k.USERWITHDISPLAYNAME]: `userWithDisplayName`,
  [k.SELECT_MEMBERS]: `Select Members`,
  [k.MEMBERS2]: `Members`,
  [k.MEMBER3]: `member-`,
  [k._41]: `30`,
  [k.PROFILE_PIC1]: `profile-pic`,
  [k.USER_NOT_FOUND]: `User not found`,
  [k.DEFINE]: `Define`,
  [k.DRAFT_PROCESS]: `Draft Process`,
  [k.SEARCH_PROCESS]: `Search Process`,
  [k.CANNOT_REMOVE_STATUS]: `Cannot remove status "`,
  [k.THERE_ARE_OPEN_CONVERSATIONS_W]: `There are open conversations with that status. You need to change the
          status on all those conversations to a different status before we can
          remove "`,
  [k.CONVERSATIONS_MARKED_AS]: `conversations marked as "`,
  [k.SEND_MAIL_WITHOUT_INVITES]: `Send mail without invites`,
  [k.SEND_WITH_INVITES]: `Send with invites`,
  [k._42]: `99+`,
  [k.CHAT_HISTORY]: `chat-history`,
  [k.BA]: `#1581BA`,
  [k._43]: `00`,
  [k.FFF]: `#fff`,
  [k._44]: `10`,
  [k._45]: `17`,
  [k._46]: `8`,
  [k._47]: `13`,
  [k._48]: `#888`,
  [k.EM3]: `1.5em`,
  [k.INITIAL]: `initial`,
  [k.A_A_A]: `#2A2A2A`,
  [k.F_A]: `#f1a208`,
  [k.BBBBBB]: `#BBBBBB`,
  [k.A_A_A1]: `#2a2a2a`,
  [k.URL_AVATAR_PATTERN]: `url(#avatar-pattern-`,
  [k.AVATAR_PATTERN]: `avatar-pattern-`,
  [k.OBJECTBOUNDINGBOX]: `objectBoundingBox`,
  [k.SCALE]: `scale(.00163)`,
  [k.GROUP_AVATAR]: `#group-avatar-`,
  [k.GROUP_AVATAR1]: `group-avatar-`,
  [k._49]: `612`,
  [k._50]: `7`,
  [k._51]: `18`,
  [k._52]: `0 0`,
  [k._53]: `12`,
  [k._54]: `24`,
  [k._55]: `#888888`,
  [k.F_ED]: `#2F80ED`,
  [k.IC_FORMAT_ALIGN_CENTER_PX]: `ic_format_align_center_24px`,
  [k.IC_FORMAT_ALIGN_JUSTIFY_PX]: `ic_format_align_justify_24px`,
  [k.IC_FORMAT_ALIGN_LEFT_PX]: `ic_format_align_left_24px`,
  [k.IC_FORMAT_ALIGN_RIGHT_PX]: `ic_format_align_right_24px`,
  [k.IC_FORMAT_LIST_BULLETED_PX]: `ic_format_list_bulleted_24px`,
  [k.IC_FORMAT_ITALIC_PX]: `ic_format_italic_24px`,
  [k.IC_FORMAT_LIST_NUMBERED_PX]: `ic_format_list_numbered_24px`,
  [k.IC_FORMAT_UNDERLINED_PX]: `ic_format_underlined_24px`,
  [k.UNABLE_TO_LOAD_DOCUMENT]: `Unable to load document.`,
  [k.TO_CREATE_AREA_HIGHLIGHT_HOLD]: `To create area highlight hold ⌥ Option key (Alt), then click and
            drag.`,
  [k._56]: `…`,
  [k.SCREENSHOT]: `Screenshot`,
  [k.RESET_HIGHLIGHTS]: `Reset highlights`,
  [k.FUNCTION]: `function`,
  [k.PAGE1]: `.page`,
  [k.ADD_COMMENT]: `Add comment`,
  [k.ADD_A_COMMENT]: `Add a Comment`,
  [k.START_TYPING_YOUR_COMMENT_HERE]: `Start typing your comment here...`,
  [k.ADD_COMMENT1]: `Add Comment`,
  [k.CUSTOM1]: `custom`,
  [k.AUTOMATIC_ZOOM]: `Automatic Zoom`,
  [k.ACTUAL_SIZE]: `Actual Size`,
  [k.PAGE_FIT]: `Page Fit`,
  [k.PAGE_WIDTH]: `Page Width`,
  [k._57]: `75%`,
  [k._58]: `125%`,
  [k._59]: `150%`,
  [k._60]: `200%`,
  [k._61]: `300%`,
  [k._62]: `400%`,
  [k.PAGE_FIT1]: `page-fit`,
  [k.PAGE_ACTUAL]: `page-actual`,
  [k.PAGE_WIDTH1]: `page-width`,
  [k.EMAIL_PLACEHOLDER]: `e.g. john.doe@unifize.com`,
  [k.NEW_PASSWORD_PLACEHOLDER]: `Enter new password`,
  [k.FILES_TOOLTIP_TITLE]: `Files`,
  [k.CHECKLIST_TOOLTIP_TITLE]: `Checklist`,
  [k.REFRESH_CHECKLIST_TOOLTIP_TITLE]: `Refresh`,
  [k.OPEN_CHECKLIST_TOOLTIP_TITLE]: `Open Checklist`,
  [k.RESTRICTED_ROOM_MODAL_MESSAGE]: `Unable toload the chatroom you're trying to access. Please try again in sometime!`,
  [k.START_NEW]: `Start new`,
  [k.FEED]: `Feed`,
  [k.MORE_OPTIONS]: `More options`,
  [k.GENERATE_JSON_SUCCESS]: `Generating JSON. Download link will be emailed to you in a couple of minutes.`,
  [k.GENERATE_JSON_FAILURE]: `Unable to generate json`,
  [k.PREVENT_REQUESTER_MESSAGE]: `Requesters cannot add signatures.`,
  [k.ENTER_A_TITLE_FOR_THIS_PROCESS]: `Enter a title for this process`,
  [k.TEXT]: `Text`,
  [k.NUMBER]: `Number`,
  [k.DATE]: `Date`,
  [k.USER]: `User`,
  [k.SECTION]: `Section`,
  [k.SUBSECTION]: `Subsection`,
  [k.CONVERSATION2]: `Conversation`,
  [k.PARENT_CONVERSATION]: `Parent Conversation`,
  [k.CHILD_CONVERSATION]: `Child Conversation`,
  [k.PDF]: `Generate PDF`,
  [k.FILE_UPLOAD]: `File Upload`,
  [k.FORM]: `Form`,
  [k.REVISION]: `Revision`,
  [k.LINKED_FIELD]: `Linked field`,
  [k.UPLOAD_FIELDS_FROM_CSV]: `Upload fields from CSV`,
  [k.UPLOAD_FIELDS_JSON]: `Upload fields from JSON`,
  [k.CHECKLIST_FIELD_TEXT_DESCRIPTION]: "Free type text field",
  [k.CHECKLIST_FIELD_NUMBER_DESCRIPTION]:
    "Enter a number. Can be used to analyse totals",
  [k.CHECKLIST_FIELD_DATE_DESCRIPTION]:
    "User entered date. Manual or date picker.",
  [k.CHECKLIST_FIELD_USER_DESCRIPTION]:
    "Select a user within your contacts or organization",
  [k.CHECKLIST_FIELD_SECTION_DESCRIPTION]:
    "Divide the checklist using prominent collapsible sections.",
  [k.CHECKLIST_FIELD_SUBSECTION_DESCRIPTION]:
    "Add a collapsible sub-section within a section",
  [k.CHECKLIST_FIELD_CONVERSATION_DESCRIPTION]:
    "Link another process as a parent conversation.",
  [k.CHECKLIST_FIELD_CHILD_CONVERSATION_DESCRIPTION]:
    "Link another process as a child conversation.",
  [k.CHECKLIST_FIELD_SELECT_DESCRIPTION]:
    "Drop-down selection of items. Optional: Users can add items.",
  [k.CHECKLIST_FIELD_PDF_DESCRIPTION]:
    "Generate a printable pdf of the checklist using a template.",
  [k.CHECKLIST_FIELD_FILE_DESCRIPTION]:
    "Attach / upload documents or images to the field.",
  [k.CHECKLIST_FIELD_FORM_DESCRIPTION]:
    "Add a pre-configured form. Useful for multiple line items.",
  [k.CHECKLIST_FIELD_APPROVAL_DESCRIPTION]:
    "Apply digital signatures to processes and documents.",
  [k.CHECKLIST_FIELD_REVISION_DESCRIPTION]:
    "Create and manage revisions of conversations",
  [k.CHECKLIST_FIELD_LINK_DESCRIPTION]: "Link to another process (both ways).",
  [k.CHECKLIST_FIELD_UPLOAD_FROM_CSV_DESCRIPTION]:
    "Upload an excel checklist sheet to quickly create a checklist",
  [k.TOOLTIP_HOMESCREEN]: "Homescreen",
  [k.TOOLTIP_DASHBOARD]: "Dashboard",
  [k.TOOLTIP_CONTACTS]: "Contacts",
  [k.TOOLTIP_MANAGE]: "Manage",
  [k.TOOLTIP_DIRECT_MESSAGES]: "Direct Messages",
  [k.SEND_RESPONSE_FROM_HERE]: "Send a response from here",
  [k.APPROVAL_PERMISSIONS]: "Approval permissions",
  [k.APPROVAL_CAN_BE_CANCELLED_BY]: "Approval can only be cancelled by",
  [k.APPROVAL_SETTINGS_NOT_CONFIGURED]:
    "The approval settings have not been configured",
  [k.DUE]: "Due",
  [k.PROCESS_WAS_DELETED]: "Process was deleted",
  [k.IMPORTED_WITH_ERRORS]: "Imported with error(s)",
  [k.SEARCH_PROCESS]: "Search Process",
  [k.ONLY_IMAGES_ARE_ALLOWED]: "Only images are allowed.",
  [k.UNABLE_TO_CLEAR_DB]: "Unable to clear database: ",
  [k.PREFERENCES]: "Preferences",
  [k.LANGUAGE]: "Language",
  [k.LANGUAGE_SWITCHING_HINT]: "Select the language you want to use in Unifize",
  [k.LANGUAGE_SUPPORT_HINT]:
    "Please contact your admin to enable language support",
  [k.FIELD_NAME]: "Field Name",
  [k.ADD_NEW_FIELD]: "Add new field",
  [k.SETTINGS]: "Settings",
  [k.SHOW_HIDE_FIELD]: "Show / Hide field",
  [k.TRANSLATIONS]: `Translations`,
  [k.SUPPORTED_LANGUAGES]: `Supported languages`,
  [k.LANGUAGE]: `Language`,
  [k.ADD_NEW]: `+ Add New`,
  [k.NO_TRANSLATIONS_FOUND]: `No translations found.`,
  [k.CONTACT_SUPPORT]: `Contact Unifize to get support for more languages.`,
  [k.PREVIOUS]: `Previous`,
  [k.PAGE_SIZE]: `Page Size`,
  [k.ENTER_PAGE_NUMBER]: `Enter page number`,
  [k.LANGUAGE_SWITCH]: `Switch Language`,
  [k.LANGUAGE_INFO]: `Languages supported by your org are:`,
  [k.ACTION]: `Action`,
  [k.PATH]: `Path`,
  [k.FAILED_TO_SWITCH_LANGUAGE]: `Failed to switch language`,
  [k.ENTER_OPTIONAL_COMMENT_HERE]: "Enter optional comment here",
  [k.SELECT_ALL_PENDING]: "Select all pending",
  [k.SELECT_ALL_COMPLETE]: "Select all complete",
  [k.PENDING_CONVERSATIONS]: "e.g. Pending Conversations",
  [k.NUM_PENDING_TASKS]: "e.g. You have #num pending tasks",
  [k.TAKE_ME_THERE]: "Take me there",
  [k.REFRESHED_CHART_DATA_NOT_AVAILABLE]:
    "Refreshed chart data not available. Please try again in a couple of minutes.",
  [k.AVAILABLE_CHARTS]: "Available Charts",
  [k.SEARCH_CHARTS]: "Search Charts",
  [k.CHART_ALREADY_EXISTS_IN_DASHBOARD]:
    "This chart already exists in the current dashboard",
  [k.FAILED_TO_LOAD_CHART]:
    "Failed to load chart data. Please refresh after some time",
  [k.FAILED_TO_LOAD_CHART_DATA]:
    "Failed to load chart. Please refresh after some time",
  [k.NO_DATA_TO_DISPLAY]: "No data to display",
  [k.DATE_UPDATED_SORT]: "DATE UPDATED",
  [k.PRIORITY_SORT]: "PRIORITY",
  [k.STATUS_SORT]: "STATUS",
  [k.DATE_CREATED_SORT]: "DATE CREATED",
  [k.DUE_DATE_SORT]: "DUE DATE",
  [k.RECENT_SORT]: "Recent",
  [k.STALE_SORT]: "Stale",
  [k.CRITICAL_SORT]: "Critical",
  [k.LOW_SORT]: "Low",
  [k.PENDING_SORT]: "Pending",
  [k.COMPLETE_SORT]: "Complete",
  [k.NEWEST_SORT]: "Newest",
  [k.OLDEST_SORT]: "Oldest",
  [k.DUE_SOON_SORT]: "Due soon",
  [k.DUE_LATER_SORT]: "Due later",
  [k.ENTER_FILTER_NAME]: "Enter Filter Name",
  [k.ENTER_GROUP_NAME]: "Enter Group Name",
  [k.ADD_OWNER]: "Add owner",
  [k.ADD_CONTACTS]: "Add Contacts",
  [k.ADD_TO_GROUP]: "Add to Group",
  [k.APPROVAL_CAN_ONLY_BE_SIGNED_BY]: "Approval can only be signed by",
  [k.ENTER_PASSWORD]: "Enter password",
  [k.ENTER_COMMENT_HERE]: "Enter comment here",
  [k.RESENDING_OTP_TO_YOUR_EMAIL]: "Re-sending OTP to your email",
  [k.OTP_SENT_TO_EMAIL]: "The OTP has been sent to your email",
  [k.FAILED_TO_SEND_OTP]: "Failed to send OTP. Please try again later",
  [k.ENTER_OTP]: "Enter 6-digit OTP",
  [k.INVITE_RECIPIENTS_TO_UNIFIZE]:
    "Do you want to invite recipients to Unifize?",
  [k.SEARCH_PEOPLE_OR_GROUPS]: "Search People or Groups",
  [k.SELECT_ROLE_FOR_NEW_PARTICIPANT]: "Select role for new participants",
  [k.ALL_COMMENTS_AND_MESSAGES]: "All comments and messages",
  [k.SEARCH_PICKLIST]: "Search picklist",
  [k.ENTER_TITLE_FOR_NEW_ITEM]: "Enter title for new item",
  [k.MARK_AS_FAVOURITE]: "Mark as favourite",
  [k.REVISION_PERMISSIONS]: "Revision permissions",
  [k.REVISIONS_CAN_BE_CREATED_BY]: "Revisions can only be created by",
  [k.REVISION_SETTINGS_NOT_CONFIGURED]:
    "The revision settings have not been configured",
  [k.ENTER_YOUR_NAME]: "Enter your name",
  [k.NEW_PASSWORD]: "New password",
  [k.GENERATE_PDF]: "Generate PDF",
  [k.MS_ADMIN_NOT_ENABLED]: "Microsoft 365 Admin is not enabled",
  [k.UNABLE_TO_CREATE_FILE]: "Unable to create file",
  [k.MS_NOT_ENABLED]: "Microsoft 365 is not enabled",
  [k.BLANK]: "Blank",
  [k.NON_BLANK]: "Non-blank",
  [k.CANCEL_REQUEST]: "Cancel Request",
  [k.CANCEL_APPROVAL]: "Cancel Approval",
  [k.OPEN]: "Open",
  [k.ENTER_OPTIONAL_TITLE]: "Enter optional title",
  [k.ENTER_ADDITIONAL_COMMENTS]: "Enter Additional Comments",
  [k.PENDING_INQUIRIES_FOR_STEAM]: "e.g. Pending Inquiries for Steam Turbines",
  [k.GRAPHICAL_REPRESENTATION_THE_PENDING_ENQUIRIES]:
    "e.g. Graphical representation of the pending enquiries for Steam Turbines categorised by owner",
  [k.SELECT_A_CHART_FOR]: "Select a chart for",
  [k.COUNT_OF_CONVERSATIONS]: "Count of conversations",
  [k.FOLLOWING_CHARTS_REPRESENT_CONVERSATIONS]:
    "Following charts represent the number of underlying conversations",
  [k.COUNT_OF_FORMS]: "Count of Forms",
  [k.FOLLOWING_CHARTS_REPRESENT_FORMS]:
    "Following charts represent the number of underlying forms",
  [k.SUM_OF_NUMERIC_FIELD]: "Sum of numeric field",
  [k.USE_CHARTS_FOR_TOTALS]:
    "Use these charts when you need total $ spent, or total hours etc.",
  [k.TIME_TRENDS]: "Time trends",
  [k.USE_CHARTS_FOR_TRENDS]:
    "Use these charts when you need see trends of counts or values over time",
  [k.COMPARISON_CHART]: "Comparison Chart",
  [k.USE_CHARTS_FOR_COMPARISON]:
    "Use these charts when you need to compare two or more data sets. Eg. defect rate vs production data",
  [k.PIE_CHART]: "Pie Chart",
  [k.BAR_CHART]: "Bar Chart",
  [k.CHECKLIST_FIELDS]: "Checklist Fields",
  [k.CONVERSATION_ATTRIBUTES]: "Conversation Attributes",
  [k.REFRESHED_PROCESS_TABLE]: "Refreshed Process table",
  [k.NEW_PROCESS_TEMPLATE]: "New Process Template",
  [k.IMPORT]: "Import",
  [k.DISPLAY_NAME]: "Name",
  [k.EMAIL_ID]: "Email id",
  [k.ROLE1]: "Role",
  [k.DEPARTMENT]: "Department",
  [k.PHONE_NUMBER]: "Phone number",
  [k.GROUPS]: "Groups",
  [k.LAST_LOGGED_IN]: "Last logged in",
  [k.DATE_INVITED]: "Date invited",
  [k.DATE_JOINED]: "Date joined",
  [k.SEARCH_FOR_ANYTHING]: "Search for anything",
  [k.DATE_UPDATED]: "Date Updated",
  [k.PRIORITY]: "Priority",
  [k.STATUS]: "Status",
  [k.DATE_CREATED]: "Date Created",
  [k.ALL_RELATED]: "All Related",
  [k.OPEN_REVISIONS]: "Open Revisions",
  [k.EXPORT]: "Export",
  [k.REMOVE_CHART_FROM_DASHBOARD]: "Remove Chart From Dashboard",
  [k.REMOVE_CHART]: "Remove Chart",
  [k.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_CHART]:
    "Are you sure you want to remove this chart from the dashboard?",
  [k.TOMORROW]: "Tomorrow",
  [k.YESTERDAY]: "Yesterday",
  [k.ALL_EMBEDDED_RECORDS]: "All embedded records",
  [k.ERROR_MODIFYING_GROUP]: "Error modifying group",
  [k.SEARCH_ORG]: "Search Org",
  [k.ERROR_CREATING_CONVERSATION]: "Error creating conversation",
  [k.DISABLE_AND_CLEAR_ALL_DATA]: "Disable and clear all data",
  [k.INVITE_SENT]: "Invite Sent",
  [k.INVITE_CLICKED]: "Invite Clicked",
  [k.EMAIL_PASSWORD_REGISTRATION]: "Email ID and Password Registration",
  [k.PHONE_DEPT_REGISTRATION]: "Phone and Dept. Registration",
  [k.SIGNIN_MOBILE]: "SignIn (Mobile)",
  [k.SIGNIN_WEB]: "SignIn (Web)",
  [k.USE_THIS_SECTION_UPCOMING_TASKS]:
    "Use this section to show upcoming tasks",
  [k.HAS_USED_PLATFORM]: "has used the platform",
  [k.IS_PART_OF_GROUP]: "Is part of group",
  [k.IS_NOT_PART_OF_GROUP]: "Isn't part of group",
  [k.UNTITLED_USER_SEGMENT]: "Untitled user segment",
  [k.SEARCH_DOMAINS]: "Search domains",
  [k.APPS]: "Apps",
  [k.APPS_DESCRIPTION]:
    "Integrate with your favorite apps and supercharge your workflow",
  [k.NO_APPS_FOUND]: "No apps found",
  [k.UNABLE_FETCH_INTEGRATIONS]: "Unable to fetch integrations",
  [k.UPDATE_INTEGRATION_FAILED]: "Updating integration failed",
  [k.DISCONNECT_INTEGRATION_FAILED]: "Disconnecting integration failed",
  [k.ENTER_TEXT]: "Enter text",
  [k.ENTER_NUMBER]: "Enter number",
  [k.NEW_FORM_TEMPLATE]: "New form template",
  [k.SELECT_CHECKLIST_FIELD]: "Select checklist field",
  [k.ENTER_CONVERSATION_TITLE]: "Enter conversation title",
  [k.TURN_OFF_REMINDERS_CONTENT]:
    "Are you sure you want to turn off reminders?",
  [k.ENTER_LABEL]: "Enter Label",
  [k.THIS_WILL_BE_X_AXIS_SERIES]: "This will be the x-axis series",
  [k.UNTITLED_SERIES]: "Untitled Series",
  [k.AREA]: "Area",
  [k.RATE]: "Rate",
  [k.LINE]: "Line",
  [k.ENTER_TRENDLINE_LABEL]: "Enter trendline label",
  [k.TOTAL_OF_NUMERICAL_FIELD]: "Total of a numerical field",
  [k.AVERAGE_OF_NUMERICAL_FIELD]: "Average of a numerical field",
  [k.NESTED_FIELDS_DESCRIPTION]:
    "For nested fields, sums inside a form/ linked field first, then averages the results",
  [k.RECORD_COUNT]: "Record Count",
  [k.SUM]: "Sum",
  [k.AVERAGE]: "Average",
  [k.AVERAGE_OF_SUM]: "Average of sum",
  [k.ALL_RECORDS_FILTER]: "All Records Filter",
  [k.ALL_RECORDS_FILTER_DESCRIPTION]:
    " - If this option is disabled (default): Only the main process records will be filtered - even if it contains multiple values - If this option is enabled: All records will also be filtered, so you will only see form records that contain the value ",
  [k.SEARCH_TYPE]: "Search Type",
  [k.SUBTITLE]: "Subtitle",
  [k.YOU_HAVE_PENDING_TASKS]: "e.g. You have pending tasks",
  [k.YOU_HAVE_NUM_PENDING_TASKS]: "e.g. You have #num pending tasks",
  [k.SEARCH_TILES]: "Search tiles",
  [k.SELECT_ITEMS]: "Select items",
  [k.WRONG_PASSWORD_MESSAGE]:
    "(Minimum 8 characters, 1 lower case character, 1 upper case character and 1 numerical character)",
  [k.CONFIRM_PASSWORD]: "Confirm Password",
  [k.UNTITLED]: "Untitled"
};

// @flow

import { Set as ImmutableSet } from "immutable";
import type { Set as ImmutableSetType } from "immutable";
import k from "../i18n/keys";

const initialCustomStatus: ImmutableSetType<number> = ImmutableSet([]);

const defaultFilter = {
  everything: {
    name: "Everything",
    text: "",
    mine: false,
    favourite: false,
    workflow: null,
    newChats: false,
    approved: false,
    sortBy: "updatedAt",
    ultimateParent: false,
    overdue: false,
    critical: false,
    active: "all",
    type: ["task", "group", "conversation", "workflow", "approval", "direct"]
  },
  conversations: {
    name: "My Conversations",
    text: "",
    mine: true,
    favourite: false,
    workflow: null,
    newChats: false,
    approved: false,
    sortBy: "updatedAt",
    ultimateParent: false,
    overdue: false,
    critical: false,
    active: "all",
    type: ["task", "group", "conversation", "workflow", "approval", "direct"],
    customStatuses: initialCustomStatus
  },
  approvals: {
    name: "Approvals",
    text: "",
    mine: true,
    favourite: false,
    workflow: null,
    newChats: false,
    approved: false,
    sortBy: "updatedAt",
    ultimateParent: false,
    overdue: false,
    critical: false,
    type: ["approval"]
  },
  tasks: {
    name: "Tasks",
    text: "",
    mine: true,
    workflow: null,
    favourite: false,
    newChats: false,
    sortBy: "updatedAt",
    approved: false,
    ultimateParent: false,
    overdue: false,
    critical: false,
    type: ["task"]
  },
  favourites: {
    name: "Favourites",
    text: "",
    mine: true,
    workflow: null,
    sortBy: "updatedAt",
    favourite: true,
    newChats: false,
    ultimateParent: false,
    approved: false,
    overdue: false,
    critical: false,
    active: "all",
    type: ["task", "group", "conversation", "workflow", "approval", "direct"]
  },
  directmessage: {
    name: "Direct Messages",
    text: "",
    mine: true,
    workflow: null,
    sortBy: "updatedAt",
    favourite: false,
    newChats: false,
    ultimateParent: false,
    approved: false,
    overdue: false,
    critical: false,
    active: "all",
    type: ["direct"],
    customStatuses: initialCustomStatus
  },
  pinned: {
    text: "",
    mine: true,
    sortBy: "updatedAt",
    favourite: false,
    newChats: false,
    ultimateParent: false,
    approved: false,
    overdue: false,
    critical: false,
    type: ["workflow"],
    customStatuses: initialCustomStatus
  },
  newConvo: {
    name: "New Conversations",
    text: "",
    mine: true,
    favourite: false,
    workflow: null,
    newChats: true,
    approved: false,
    sortBy: "updatedAt",
    ultimateParent: false,
    overdue: false,
    critical: false,
    type: ["task", "group", "conversation", "workflow", "approval", "direct"]
  },
  overdue: {
    name: "Overdue Conversations",
    text: "",
    mine: true,
    favourite: false,
    workflow: null,
    newChats: false,
    approved: false,
    sortBy: "updatedAt",
    ultimateParent: false,
    overdue: true,
    critical: false,
    type: ["task", "group", "conversation", "workflow", "approval", "direct"],
    active: "all"
  },
  critical: {
    name: "Critical Conversations",
    text: "",
    mine: true,
    favourite: false,
    workflow: null,
    newChats: false,
    approved: false,
    sortBy: "updatedAt",
    ultimateParent: false,
    overdue: false,
    critical: true,
    type: ["task", "group", "conversation", "workflow", "approval", "direct"]
  }
};

const sorByItemNames = {
  updatedAt: k.DATE_UPDATED,
  priority: k.PRIORITY,
  status: k.STATUS,
  createdAt: k.DATE_CREATED,
  dueDate: k.DUE_DATE,
  "updatedAt:ascending": k.DATE_UPDATED,
  "priority:ascending": k.PRIORITY,
  "status:ascending": k.STATUS,
  "createdAt:ascending": k.DATE_CREATED,
  "dueDate:ascending": k.DUE_DATE
};

const statusNames = {
  pending: "Pending",
  closed: "Complete",
  custom: "Custom"
};

export default defaultFilter;
export { sorByItemNames, statusNames };
